export const REFRESH = 'REFRESH'
export const RESET_EASYPZ_TRANSFORM_PARAMS =
  'RESET_EASYPZ_TRANSFORM_PARAMS'
export const SET_ALLOW_COLLEAGUES = 'SET_ALLOW_COLLEAGUES'
export const SET_ALERT = 'SET_ALERT'
export const SET_DESKS = 'SET_DESKS'
export const SET_FLOORS = 'SET_FLOORS'
export const SET_RESTRICTED_FLOORS = 'SET_RESTRICTED_FLOORS'
export const SET_EASY_PZ_TRANSFORM_PARAMS =
  'SET_EASY_PZ_TRANSFORM_PARAMS'
export const SET_IS_AUTHENTICATED = 'SET_IS_AUTHENTICATED'
export const SET_IS_FORM_DIRTY = 'SET_IS_FORM_DIRTY'
export const SET_GOOGLE_ROOMS = 'SET_GOOGLE_ROOMS'
export const SET_IS_MOBILE = 'SET_IS_MOBILE'
export const SET_IS_KIOSK = 'SET_IS_KIOSK'
export const SET_KEYCLOAK_USER_PROFILE = 'SET_KEYCLOAK_USER_PROFILE'
export const SET_LOGO_URL = 'SET_LOGO_URL'
export const SET_INFO_URL = 'SET_INFO_URL'
export const SET_READY = 'SET_READY'
export const SET_ROOMS = 'SET_ROOMS'
export const SET_AUTHORISATIONS = 'SET_AUTHORISATIONS'
export const UPDATE_AUTHORISATION = 'UPDATE_AUTHORISATION'
export const CREATE_AUTHORISATION = 'CREATE_AUTHORISATION'
export const SET_SETTINGS = 'SET_SETTINGS'
export const SET_TIMEPICKER_STATE = 'SET_TIMEPICKER_STATE'
export const SET_USER_INFO = 'SET_USER_INFO'
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO'
export const ENABLE_ALL_USERS_TO_INVITE_GUESTS =
  'ENABLE_ALL_USERS_TO_INVITE_GUESTS'
export const SET_BUILDING_USERS = 'SET_BUILDING_USERS'
export const SET_ORG_USERS = 'SET_ORG_USERS'
export const SET_LOADING = 'SET_LOADING'
export const DELETE_ORG = 'DELETE_ORG'
export const SET_ERROR = 'SET_ERROR'
export const SET_ATTRIBUTES = 'SET_ATTRIBUTES'
export const SET_ATTRIBUTES_API_MESSAGE = 'SET_ATTRIBUTES_API_MESSAGE'
export const SET_ATTRIBUTES_API_RESPONSE_CODE =
  'SET_ATTRIBUTES_API_RESPONSE_CODE'
export const SET_AZURE_ROOMS = 'SET_AZURE_ROOMS'
export const ALLOW_DESK_RESERVATION_SUBJECT =
  'ALLOW_DESK_RESERVATION_SUBJECT'
export const ALLOW_ROOM_RESERVATION_SUBJECT =
  'ALLOW_ROOM_RESERVATION_SUBJECT'
export const SET_SENSOR_HISTORY_PARAMS = 'SET_SENSOR_HISTORY_PARAMS'
export const SET_ALL_USERS_LOADING_STATE =
  'SET_ALL_USERS_LOADING_STATE'
export const TOGGLE_OLD_OPENING_HOURS = 'TOGGLE_OLD_OPENING_HOURS'
export const TOGGLE_PARENT_CHECKBOX = 'TOGGLE_PARENT_CHECKBOX'
export const UPDATE_RESERVATION_SUBJECT_VISIBILITY =
  'UPDATE_RESERVATION_SUBJECT_VISIBILITY'
export const REVIEW_PLATFORM = 'REVIEW_PLATFORM'
export const UPDATE_POP_UP_TEXT_STATUS = 'UPDATE_POP_UP_TEXT_STATUS'
export const SET_ORGANISATION_NAME = 'SET_ORGANISATION_NAME'
export const SET_EMPTY_ASSETS = 'SET_EMPTY_ASSETS'
