<template>
  <div>
    <div class="row pb-4 mr-0 df">
      <h2 class="m-0 col">{{ $t('reservations') }}</h2>
      <div>
        <input
          v-model="search"
          style="width: 200px"
          type="text"
          class="form-control d-inline-block mr-2"
          :placeholder="$t('filterTable')"
        />
        <span
          v-b-tooltip.hover
          class="tip-icon"
          :title="$t('reservationFilterHint')"
        >
          <font-awesome-icon icon="question-circle" />
        </span>
      </div>
      <b-form-checkbox v-model="showRooms" class="my-2 mx-2">
        <span class="option-label">
          {{ $t('showRooms') }}
        </span>
      </b-form-checkbox>

      <b-form-checkbox v-model="showDesks" class="my-2 mx-2">
        <span class="option-label">
          {{ $t('showDesks') }}
        </span>
      </b-form-checkbox>
      <div>
        <DatePickerForReservations
          place-holder="filterPastReservation"
          :min-date="minDate"
          :reservation-filter-date="pastReservationFilterDate"
          input-class="form-control"
          @setFilterDate="
            date => setFilterDateForPastReservation(date)
          "
          @resetFilter="resetPastReservationFilter"
        />
      </div>
      <b-form-select v-model="perPage" style="width: 60px">
        <option>5</option>
        <option>25</option>
        <option>50</option>
        <option>100</option>
      </b-form-select>
    </div>
    <div class="tabe-responsive">
      <b-overlay :show="!ready" rounded="sm">
        <table
          ref="table"
          class="table-style table-settings table_sort"
        >
          <thead>
            <tr>
              <th>{{ $t('room') }}</th>
              <th>{{ $t('desk') }}</th>
              <th>{{ $t('reservedFor') }}</th>
              <th>{{ $t('details') }}</th>
              <th>
                {{ $t('start') }}
                <font-awesome-icon
                  class="sort-icon"
                  :icon="
                    sortBy === 'start_datetime'
                      ? sortAsc
                        ? 'sort-up'
                        : 'sort-down'
                      : 'sort'
                  "
                  @click="changeSort('start_datetime')"
                />
              </th>
              <th>
                {{ $t('end') }}
                <font-awesome-icon
                  class="sort-icon"
                  :icon="
                    sortBy === 'end_datetime'
                      ? sortAsc
                        ? 'sort-up'
                        : 'sort-down'
                      : 'sort'
                  "
                  @click="changeSort('end_datetime')"
                />
              </th>
              <th>
                {{ $t('checkedIn') }}
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            <template v-for="(reservation, index) in reservations">
              <tr
                :key="index"
                :data-val="
                  reservation.objectName +
                    ' ' +
                    reservation.forUser.username +
                    ' ' +
                    getRoomByReservation(reservation) +
                    ' ' +
                    getReservedForLabel(reservation.forUser)
                "
              >
                <td :data-label="$t('room') + ':'">
                  {{ getRoomByReservation(reservation) }}
                </td>
                <td :data-label="$t('desk') + ':'">
                  {{
                    ['DESK', 'PARKING'].includes(
                      reservation.objectType
                    )
                      ? reservation.objectName
                      : ''
                  }}
                </td>
                <td :data-label="$t('reservedFor') + ':'">
                  {{ getReservedForLabel(reservation.forUser) }}
                </td>
                <td :data-label="$t('guestName') + ':'">
                  <font-awesome-icon
                    v-if="reservationGuests(reservation)"
                    :id="`tooltip-guest-icon${reservation.id}`"
                    icon="user-friends"
                    class="m-1"
                  />
                  <font-awesome-icon
                    v-if="hasReservationAssets(reservation)"
                    :id="`tooltip-assets-${reservation.id}`"
                    icon="tools"
                    class="m-1"
                  />
                  <b-tooltip
                    v-if="hasReservationAssets(reservation)"
                    :target="`tooltip-assets-${reservation.id}`"
                    placement="bottom"
                  >
                    <ul class="tooltip-list-items">
                      <li
                        v-for="(asset,
                        assetIndex) in reservation.reservationAssets"
                        :key="assetIndex"
                      >
                        {{ asset.name }}
                      </li>
                    </ul>
                  </b-tooltip>
                  <b-tooltip
                    v-if="reservationGuests(reservation)"
                    :target="`tooltip-guest-icon${reservation.id}`"
                    placement="bottom"
                  >
                    {{ $t('for') }}
                    <ul class="tooltip-list-items">
                      <li
                        v-for="(guest,
                        guestIndex) in reservation.guest"
                        :key="guestIndex"
                      >
                        {{ guest }}
                      </li>
                    </ul>
                  </b-tooltip>

                  <font-awesome-icon
                    v-if="reservation.reservationSubject"
                    :id="`tooltip-subject-icon${reservation.id}`"
                    class="tip-icon m-1"
                    icon="comment-alt"
                  />

                  <b-tooltip
                    v-if="reservation.reservationSubject"
                    :target="`tooltip-subject-icon${reservation.id}`"
                    placement="bottom"
                  >
                    <ul class="tooltip-list-items">
                      <li>
                        {{ reservation.reservationSubject }}
                      </li>
                    </ul>
                  </b-tooltip>
                </td>
                <td :data-label="$t('startsOn') + ':'">
                  {{
                    reservation.startDatetime
                      | moment(
                        'timezone',
                        timezone,
                        'D-MMM-YYYY H:mm'
                      )
                  }}
                </td>
                <td :data-label="$t('endsOn') + ':'">
                  {{
                    reservation.endDatetime
                      | moment('timezone', timezone, 'H:mm')
                  }}
                </td>
                <td :data-label="$t('checkedIn')">
                  <template
                    v-if="reservation.context.checkInDatetime"
                  >
                    {{
                      reservation.context.checkInDatetime
                        | moment('timezone', timezone, 'H:mm')
                    }}
                  </template>
                  <template v-else>
                    <span
                      :class="
                        reservation.startDatetime < currentDate
                          ? 'btn btn-secondary btn-sm disabled'
                          : 'btn btn-secondary btn-sm'
                      "
                      @click="checkinReservation(reservation.id)"
                      >{{ $t('checkIn') }}</span
                    >
                  </template>
                </td>
                <td data-label>
                  <span
                    :class="
                      reservation.startDatetime < currentDate
                        ? 'action link-edit text-base disabled'
                        : 'link-remove'
                    "
                    @click="
                      () => {
                        reservationToCancel = reservation.hash
                        $bvModal.show('modal-cancelReservation')
                      }
                    "
                  >
                    <font-awesome-icon icon="times" />
                    {{ $t('cancel') }}
                  </span>
                </td>
              </tr>
            </template>
            <div v-if="!reservations || !reservations.length">
              <tr>
                <td>
                  <strong>{{ $t('noRecordsFound') }}</strong>
                </td>
              </tr>
            </div>
          </tbody>
        </table>
      </b-overlay>
    </div>
    <div
      v-if="reservations && reservations.length"
      class="overflow-auto"
    >
      <b-pagination
        v-model="currentPage"
        :total-rows="count"
        :per-page="perPage"
        :first-text="$t('first')"
        :last-text="$t('last')"
        align="center"
        @input="pastOrAllReservations"
      />
    </div>
    <b-modal
      id="modal-cancelReservation"
      :on-enter-key-press="removeReservation"
      hide-footer
    >
      <template v-slot:modal-title>
        <strong>{{ $t('cancelReservation') }}</strong>
      </template>
      <div class="d-block text-center">
        <p>{{ $t('deleteConfirmation') }}</p>
        <b-button
          variant="danger"
          class="mt-1"
          @click="$bvModal.hide('modal-cancelReservation')"
        >
          {{ $t('no') }}
        </b-button>
        <b-button class="mt-1" primary @click="removeReservation">
          {{ $t('yes') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import moment from 'moment-timezone'
import Vue from 'vue'
import vueMoment from 'vue-moment'
import { mapGetters, mapState } from 'vuex'
import OkkuApi from '@/services/OkkuApi'
import BootstrapModalHOC from '@/components/hocs/BootstrapModal'
import DatePickerForReservations from '@/components/common/DatePickerForReservations'
import 'vue-datetime/dist/vue-datetime.css'

Vue.use(vueMoment, { moment })

export default {
  name: 'AllReservationsTable',
  components: {
    'b-modal': BootstrapModalHOC,
    DatePickerForReservations
  },
  data() {
    return {
      reservations: [],
      reservationToCancel: '',
      currentIndex: null,
      currentPage: 1,
      perPage: 25,
      count: 0,
      search: '',
      timeoutHandler: null,
      sortBy: 'start_datetime',
      sortAsc: true,
      ready: false,
      pastReservationFilterDate: '',
      minDate: '',
      currentDate: null,
      originalReservations: [],
      originalPaginationCount: 0,
      showRooms: true,
      showDesks: true
    }
  },
  computed: {
    ...mapState('common', {
      userinfo: '$userinfo',
      organisation: '$organisation'
    }),
    ...mapState('common', ['desks']),
    ...mapGetters('common', {
      timezone: 'buildingTimezone'
    })
  },
  watch: {
    search() {
      this.searchReservations()
    },
    perPage() {
      this.pastOrAllReservations()
    },
    organisation() {
      this.getReservations()
    },
    showRooms() {
      this.getReservations()
    },
    showDesks() {
      this.getReservations()
    }
  },
  mounted() {
    if (this.organisation) {
      this.getReservations()
    }
    this.currentDate = moment()
      .subtract(1, 'days')
      .toISOString()
    this.minDate = moment()
      .subtract(7, 'day')
      .toISOString()
  },
  methods: {
    async getAllReservations(fromDate, toDate = null) {
      // Convert with "+" boolean value to integer for preparation as GET parameters
      const filters = {
        showRooms: +this.showRooms,
        showDesks: +this.showDesks
      }
      const data = await OkkuApi.getReservations(
        fromDate,
        null,
        this.currentPage,
        this.perPage,
        this.search,
        this.sortBy,
        this.sortAsc ? 'ASC' : 'DESC',
        toDate,
        filters
      )

      return data
    },

    changeSort(sortBy) {
      if (sortBy === this.sortBy) {
        this.sortAsc = !this.sortAsc
      } else {
        this.sortBy = sortBy
      }
      this.pastOrAllReservations()
    },
    resetPastReservationFilter() {
      this.currentPage = 1
      if (this.pastReservationFilterDate) {
        this.getReservations()
        if (this.search) {
          this.searchReservations()
        }
      } else {
        this.pastReservationFilterDate = ''
      }
    },
    async getReservations() {
      this.pastReservationFilterDate = ''
      this.ready = false

      const fromDate = moment()
        .subtract(1, 'days')
        .toISOString()

      const data = await this.getAllReservations(fromDate)

      this.ready = true
      this.reservations = data.reservations
      this.originalReservations = [...data.reservations]
      this.originalPaginationCount = data.count
      if (data.count) {
        this.count = data.count
      }
    },
    getRoomByReservation(reservation) {
      if (reservation.objectType === 'ROOM') {
        return reservation.objectName
      }
      return this.desks.find(d => d.id === reservation.objectId)
        ?.roomName
    },
    updateAlert(variant, text) {
      this.$emit('updateAlert', { variant, text })
    },
    checkinReservation(id) {
      OkkuApi.checkInReservation(id)
        .then(response => {
          this.updateAlert('success', this.$t('changesSaved'))
          this.getReservations()
        })
        .catch(error => {
          this.updateAlert('danger', error.message)
        })
    },
    removeReservation() {
      OkkuApi.cancelReservation(this.reservationToCancel)
        .then(() => {
          this.updateAlert('success', this.$t('changesSaved'))
          this.reservations = this.reservations.filter(
            ({ hash }) => hash !== this.reservationToCancel
          )
        })
        .catch(error => {
          if (error.data) this.updateAlert('danger', error.data)
          else this.updateAlert('danger', error.message)
        })
        .finally(() => {
          this.$bvModal.hide('modal-cancelReservation')
        })
    },
    getReservedForLabel(user) {
      let label = ''
      if (user && user.firstname && user?.lastname) {
        label = `${user.firstname} ${user?.lastname}`
      } else return label

      if (user.isEmergencyResponder) {
        label += ` ${this.$t('emergencyResponder')}`
      }
      return label
    },

    async setFilterDateForPastReservation(fromDate, flag = false) {
      this.pastReservationFilterDate = fromDate
      if (!flag) {
        this.currentPage = 1
      }
      const toDate = moment
        .utc(fromDate)
        .endOf('day')
        .toISOString()

      this.ready = false

      const data = await this.getAllReservations(fromDate, toDate)
      this.reservations = data.reservations
      if (data.count) {
        this.count = data.count
      }
      this.ready = true
    },

    reservationGuests(reservation) {
      if (reservation.guest[0]) {
        return true
      }
      return false
    },
    searchReservations() {
      clearTimeout(this.timeoutHandler)
      this.timeoutHandler = setTimeout(() => {
        this.page = 1
        this.pastOrAllReservations()
      }, 500)
    },
    pastOrAllReservations() {
      if (this.pastReservationFilterDate) {
        this.setFilterDateForPastReservation(
          this.pastReservationFilterDate,
          true
        )
      } else {
        this.getReservations()
      }
    },
    hasReservationAssets(reservation) {
      return (
        reservation.reservationAssets &&
        reservation.reservationAssets.length !== 0
      )
    }
  }
}
</script>
<style lang="scss" scoped>
#timeslot-modal {
  .vdatetime-input {
    border: none;
    width: 100%;
  }
  .invalid-feedback {
    display: block;
  }
  .clear-input-wrapper {
    position: absolute;
    right: 78px;
    top: 8px;
    svg {
      cursor: pointer;
    }
  }
}
.tip-icon::v-deep {
  .fa-question-circle {
    font-size: 16px;
    margin-right: 5px;
    cursor: pointer;
  }
}
.svg-inline--fa {
  display: inline-block;
  font-size: 18px;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
table {
  input {
    display: inline-block;
    vertical-align: middle;
  }
  .sort-icon {
    cursor: pointer;
  }
}
ul.tooltip-list-items {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
</style>
<style lang="scss" scoped>
.form-group::v-deep {
  .inp-style {
    padding: 0;
    input {
      border: none;
      width: 100%;
      background-color: transparent;
      padding: 0.25rem 0.5rem;
    }
  }
}
.form-group-small::v-deep {
  width: 56px;
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
  input {
    text-align: center;
  }
}

@media (max-width: 760px) {
  .row {
    display: block !important;
  }
}
</style>
