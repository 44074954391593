<template>
  <div
    id="timeslot-modal"
    class="row position-relative align-items-center"
  >
    <div class="position-relative">
      <datetime
        :value="reservationFilterDate"
        style="width: 230px"
        input-class="form-control"
        class="col-md-auto df"
        type="date"
        :placeholder="$t(`${placeHolder}`)"
        zone="utc"
        :minute-step="1"
        :max-datetime="maxDatetime"
        :min-datetime="minDatetime"
        @input="val => setFilterDate(val)"
      />
      <div class="clear-input-wrapper">
        <font-awesome-icon
          icon="trash"
          @click="$emit('resetFilter')"
        />
      </div>
    </div>
    <div class="col m-2">
      <span v-b-tooltip.hover class="tip-icon" :title="title">
        <font-awesome-icon icon="question-circle" />
      </span>
    </div>
  </div>
</template>

<script>
import { Datetime } from 'vue-datetime'
import moment from 'moment'
import 'vue-datetime/dist/vue-datetime.css'

export default {
  name: 'DateTimePickerForReservations',
  components: {
    Datetime
  },
  props: {
    reservationFilterDate: {
      type: String,
      required: true
    },
    placeHolder: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    minDatetime: {
      type: String,
      required: true
    },
    maxDatetime: {
      type: String,
      required: false
    }
  },

  methods: {
    setFilterDate(val) {
      if (!moment(val).isSame(this.reservationFilterDate)) {
        this.$emit('setFilterDate', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#timeslot-modal {
  .clear-input-wrapper {
    position: absolute;
    // right: 780px;
    right: 30px;
    top: 8px;
    svg {
      cursor: pointer;
    }
  }
}

.svg-inline--fa {
  display: inline-block;
  font-size: 18px;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
</style>
