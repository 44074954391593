<template>
  <div id="app">
    <AppHeader />
    <review-component v-if="hasOrganisationAccess && !hideFeedback" />
    <router-view />
    <div v-if="!hideFooter" class="foot-logo">
      <a href="/">
        <img src="@/assets/img/okkuLogo.png" height="30px" alt />
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import AppHeader from '@/components/layout/AppHeader'
import ReviewComponent from '@/components/misc/ReviewComponent'
import {
  SET_EASY_PZ_TRANSFORM_PARAMS,
  SET_IS_MOBILE,
  SET_IS_KIOSK
} from '@/store/modules/common/mutationTypes'

export default {
  name: 'App',
  components: {
    AppHeader,
    ReviewComponent
  },
  computed: {
    hideFeedback() {
      return !!this.$route.meta.hideFeedback
    },
    hideFooter() {
      return !!this.$route.meta.hideFooter
    },
    ...mapGetters('common', {
      hasOrganisationAccess: 'hasOrganisationAccess',
      isMobileDevice: 'isMobileDevice'
    })
  },
  watch: {
    hasOrganisationAccess(hasOrganisationAccess) {
      if (!hasOrganisationAccess) {
        this.$router.push('/no-access')
      }
    }
  },
  created() {
    window.addEventListener('resize', this.onWindowResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onWindowResize)
  },
  mounted() {
    window.onEasyPzTransform = params => {
      this.$store.commit(`common/${SET_EASY_PZ_TRANSFORM_PARAMS}`, {
        ...params
      })
    }

    this.onWindowResize()
    if (window.location.href.includes('kiosk=true')) {
      this.$store.commit(`common/${SET_IS_KIOSK}`, true)
      this.inactivityTime()
    }
  },
  methods: {
    resetTimer() {
      clearTimeout(this.interval)
      this.interval = setTimeout(this.countDown, 30000)
    },
    inactivityTime() {
      document.onload = this.resetTimer
      document.onmousemove = this.resetTimer
      document.onmousedown = this.resetTimer
      document.ontouchstart = this.resetTimer
      document.onclick = this.resetTimer
      document.onscroll = this.resetTimer
      document.onkeypress = this.resetTimer
    },
    countDown() {
      clearTimeout(this.interval)
      this.$cookie.delete('lang')
      window.location.href = '/logout?kiosk=true'
    },
    onWindowResize() {
      if (window.innerWidth >= 992) {
        if (this.isMobileDevice) {
          this.$store.commit(`common/${SET_IS_MOBILE}`, false)
        }
      } else if (!this.isMobileDevice) {
        this.$store.commit(`common/${SET_IS_MOBILE}`, true)
      }
    }
  }
}
</script>

<style>
#app {
  font-family: 'Poppins', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
}
.foot-logo a {
  display: inline-block;
  opacity: 0.2;
  margin: 20px;
  transition: opacity 0.2s;
}
.foot-logo a:hover {
  opacity: 1;
}
@media (max-width: 760px) {
  .section-settings {
    padding-top: 10px !important;
  }
}
</style>
