<template>
  <header id="main-nav">
    <b-navbar
      v-if="hasOrganisationAccess"
      toggleable="xl"
      type="light"
    >
      <div class="mobile-top col-xl-auto">
        <b-navbar-brand>
          <b-nav-item :to="{ name: 'BuildingsPage' }">
            <img
              :src="organisationUrls.logoUrl"
              class="logo-top"
              alt
            />
          </b-nav-item>
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse" />
      </div>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="col">
          <template v-if="activeGroup">
            <b-nav-item
              v-if="reservationsEnabled"
              class="d-none d-xl-block"
              :to="{ name: 'WorkplaceReservationPage' }"
            >
              <span class="title">{{ activeGroup.orgName }}</span>
            </b-nav-item>
            <b-nav-item
              v-if="!reservationsEnabled && sensorsEnabled"
              class="d-none d-xl-block"
              :to="{ name: 'SensorsDataPage' }"
            >
              <span class="title">{{ activeGroup.orgName }}</span>
            </b-nav-item>
          </template>
          <div
            v-if="
              pageName != 'BuildingsPage' && userHasMultipleLocations
            "
            class="change-button"
          >
            <div>
              <router-link
                class="btn btn-secondary"
                :title="$t('changeBuilding')"
                :to="{ name: 'BuildingsPage' }"
              >
                {{ $t('locations') }}
              </router-link>
            </div>
          </div>
          <template
            v-if="userInfo.roles && pageName != 'BuildingsPage'"
          >
            <b-nav-item
              v-if="isAdminOrCoordinator"
              :to="{ name: 'AllReservationsPage' }"
            >
              {{ $t('manage') }}
            </b-nav-item>

            <b-nav-item-dropdown
              v-if="sensorsEnabled"
              toggle-class="text-decoration-none"
              right
            >
              <template v-slot:button-content>
                {{ $t('sensors') }}
              </template>

              <b-dropdown-item :to="{ name: 'SensorsDataPage' }">
                {{ $t('sensorsOnMap') }}
              </b-dropdown-item>
              <b-dropdown-item :to="{ name: 'ClimateIndicators' }">
                {{ $t('climateIndicators') }}
              </b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item-dropdown
              v-if="insightsEnabled"
              right
              toggle-class="text-decoration-none"
            >
              <template v-slot:button-content>
                {{ $t('insights') }}
              </template>
              <b-dropdown-item :to="{ name: 'UserSummaries' }">
                {{ $t('userSummaries') }}
              </b-dropdown-item>
              <b-dropdown-item :to="{ name: 'HourlyAvailability' }">
                {{ $t('hourlyAvailability') }}
              </b-dropdown-item>
              <b-dropdown-item
                :to="{
                  name: 'ReservationsAndCancellationsStatistics'
                }"
              >
                {{ $t('reservationsAndCancellationsStatistics') }}
              </b-dropdown-item>

              <b-dropdown-item :to="{ name: 'ReservationDetails' }">
                {{ $t('reservations') }}
              </b-dropdown-item>
              <b-dropdown-item :to="{ name: 'FixedDesks' }">
                {{ $t('fixedDesks') }}
              </b-dropdown-item>
              <b-dropdown-item
                v-if="isDashboardAdmin || hasViewCustomAssetRole"
                :to="{ name: 'AssetsAvailability' }"
              >
                {{ $t('assetsAvailability') }}
              </b-dropdown-item>
              <b-dropdown-item :href="userInfo.insightsLink">
                {{ $t('customReports') }}
                <font-awesome-icon
                  class="mb-1 ml-3"
                  icon="external-link-alt"
                ></font-awesome-icon>
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </template>
          <b-nav-item
            v-if="reservationsEnabled"
            :to="{ name: 'MyReservationsPage' }"
          >
            {{ $t('myReservations') }}
          </b-nav-item>
          <b-nav-item
            v-if="
              findMyColleaguesEnabled && pageName !== 'BuildingsPage'
            "
            :to="{ name: 'FindMyColleaguesPage' }"
          >
            {{ $t('findMyColleague') }}
          </b-nav-item>
          <b-nav-item
            v-if="organisationUrls.infoUrl && !isKiosk"
            target="_blank"
            :href="organisationUrls.infoUrl"
          >
            {{ $t('faq') }}
          </b-nav-item>
          <b-nav-item
            v-if="!organisationUrls.infoUrl && !isKiosk"
            target="_blank"
            href="https://okku.io/support"
          >
            {{ $t('faq') }}
          </b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="align-self-end  col-md-auto">
          <b-nav-item-dropdown
            class="dropdown-user"
            toggle-class="text-decoration-none"
            left
          >
            <template v-slot:button-content>
              <font-awesome-icon icon="globe" />
              {{ currentLang }}
            </template>
            <b-dropdown-item
              v-for="entry in languages"
              :key="entry.title"
              href="#"
              @click="changeLocale(entry.language, entry.title)"
            >
              <flag :iso="entry.flag" :squared="false" />
              {{ entry.title }}
            </b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown
            class="dropdown-user"
            right
            toggle-class="text-decoration-none"
          >
            <template v-slot:button-content>
              <font-awesome-icon icon="user-circle" />
              {{ userInfo.userDisplayName }}
              <template v-if="!showRewardsButton">
                <RewardsPageGuiding />
              </template>
            </template>
            <b-dropdown-item
              v-if="isDashboardAdmin"
              :to="{ name: 'OrganisationsAdminPage' }"
            >
              {{ $t('organisations') }}
            </b-dropdown-item>
            <b-dropdown-item
              v-if="isDashboardAdmin"
              :to="{ name: 'BuildingsAdminPage' }"
            >
              {{ $t('buildings') }}
            </b-dropdown-item>
            <b-dropdown-divider
              v-if="isDashboardAdmin"
            ></b-dropdown-divider>
            <b-dropdown-item
              v-if="
                reservationsEnabled &&
                  userInfo.showPlannedAbsencesScreen
              "
              :to="{ name: 'MyAbsencesPage' }"
            >
              {{ $t('myAbsences') }}
            </b-dropdown-item>
            <b-dropdown-item v-b-modal.modal-account>
              <font-awesome-icon icon="user-circle" />
              {{ $t('account') }}
              <template v-if="!showRewardsButton">
                <RewardsPageGuiding />
              </template>
            </b-dropdown-item>
            <b-dropdown-item disabled>
              <font-awesome-icon icon="user-friends" />
              {{ $t('myTeams') }}
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-navbar v-else toggleable="lg" type="light">
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-right">
        <b-dropdown
          class="dropdown-user"
          right
          variant="link"
          toggle-class="text-decoration-none"
        >
          <template v-slot:button-content>
            <font-awesome-icon icon="user-circle" />
            {{ userInfo.userDisplayName }}
          </template>
          <b-dropdown-item v-b-modal.modal-account>
            <font-awesome-icon icon="user-circle" />
            {{ $t('account') }}
          </b-dropdown-item>
        </b-dropdown>
      </b-navbar-nav>
    </b-navbar>
  </header>
</template>

<script>
import i18n from '@/plugins/i18n'
import { mapGetters, mapState } from 'vuex'
import moment from 'moment'
import axios from 'axios'
import OkkuApi from '@/services/OkkuApi'
import RewardsPageGuiding from '@/components/common/RewardsPageGuiding'
import { COMMUNICATION_CONSTANTS } from '@/constants'

export default {
  name: 'MainNav',
  components: {
    RewardsPageGuiding
  },
  data() {
    return {
      languages: [
        { flag: 'us', language: 'en', title: 'English' },
        { flag: 'nl', language: 'nl', title: 'Dutch' },
        { flag: 'es', language: 'es', title: 'Spanish' },
        { flag: 'fr', language: 'fr', title: 'French' },
        { flag: 'de', language: 'de', title: 'German' }
      ],
      currentLang: 'English',
      showRewardsButton: true
    }
  },
  computed: {
    pageName() {
      return this.$route.name
    },
    ...mapState('common', {
      organisation: '$organisation',
      organisationUrls: 'organisationUrls',
      userInfo: '$userInfo',
      isKiosk: 'isKiosk',
      isDashboardAdmin: '$isDashboardAdmin'
    }),
    ...mapGetters('common', [
      'findMyColleaguesEnabled',
      'hasOrganisationAccess',
      'reservationsEnabled',
      'sensorsEnabled',
      'insightsEnabled',
      'isAdmin',
      'isAdminOrCoordinator',
      'hasViewCustomAssetRole',
      'activeGroup'
    ]),

    userHasMultipleLocations() {
      return (
        this.userInfo?.groups &&
        Object.keys(this.userInfo.groups).length > 1
      )
    }
  },
  async mounted() {
    // Listen for public message from account modal
    this.$root.$on(
      COMMUNICATION_CONSTANTS.REWARDS_PAGE_INFO_FROM_ACCOUNT_PAGE_TO_MAIN_NAV_BAR,
      data => {
        if (data) {
          this.showRewardsButton = true
        } else {
          this.showRewardsButton = false
        }
      }
    )
    setTimeout(async () => {
      // Get status of rewards page
      this.showRewardsButton = await OkkuApi.getRewardsScreenVisibilityStatus(
        this.userInfo.userId
      )
      this.$root.$emit(
        COMMUNICATION_CONSTANTS.REWARDS_PAGE_STATUS_INFO,
        this.showRewardsButton
      )
    }, 3000)
    const userLang = navigator.language
    if (
      this.$cookie.get('lang') &&
      this.$cookie?.get('lang')?.length
    ) {
      for (let i = 0; i < this.languages.length; i += 1) {
        if (this.languages[i].language === this.$cookie.get('lang')) {
          this.changeLocale(
            this.$cookie.get('lang'),
            this.languages[i].title
          )
        }
      }
    } else if (userLang) {
      this.languages.forEach(item => {
        if (userLang.startsWith(item.language)) {
          this.changeLocale(item.language, item.title)
        }
      })
    }
    axios.interceptors.request.use(
      config => {
        config.headers['Accept-Language'] = i18n.locale
        return config
      },
      error => Promise.reject(error)
    )
  },
  methods: {
    // async navigateToRewardsPage() {
    //   try {
    //     // Update rewards page status
    //     await OkkuApi.updateRewardsScreenVisibilityStatus(
    //       this.userInfo.userId
    //     )
    //     // Hide rewards button
    //     this.showRewardsButton = true

    //     // Move to rewards page
    //     this.$router.push('/workplace/rewards')
    //   } catch (error) {
    //     this.showRewardsButton = false
    //   }
    // },
    changeLocale(locale, title) {
      i18n.locale = locale
      moment.locale(this.$i18n.locale)
      this.$cookie.set('lang', i18n.locale, 30)
      this.currentLang = title
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/globals/vars.scss';
@import '@/assets/scss/globals/mixins.scss';
@import '@/assets/scss/globals/extend.scss';

#main-nav {
  .navbar {
    border-bottom: 1px solid $bordercolor;
    padding-top: 15px;
    padding-bottom: 15px;
    .nav-link {
      padding-top: 12px;
      padding-bottom: 10px;
    }
  }
  .navbar-nav {
    /*justify-content: flex-end;*/
    &.ml-right {
      justify-content: flex-end;
    }
  }
  .navbar-brand {
    margin-right: 1rem;
    @include r(500) {
      display: block;
      margin-right: 0;
      text-align: center;
    }
  }
  .logo {
    width: 100px;
    display: inline-block;
    @extend %hov-opacity;
    img {
      @extend %imginside;
    }
  }
  .navbar-light .navbar-toggler {
    border: none;
    outline: none;
  }
  .nav-item {
    text-align: right;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.4px;
    .router-link-exact-active {
      color: $basecolor;
    }
    @include r(1199) {
      text-align: center;
    }
    @include rm(1199) {
      a {
        white-space: nowrap;
      }
    }
  }
  .dropdown-user {
    .fa-user-circle,
    .far,
    .fa,
    .svg-inline--fa {
      display: inline-block;
      vertical-align: middle;
      margin-right: 3px;
      font-size: 16px;
      color: $textcolor-light;
    }
    text-decoration: none;
    text-transform: none;
    & > .dropdown-toggle {
      display: inline-block;
      vertical-align: middle;
      color: $textcolor !important;
      font-weight: 400;
      font-size: 14px !important;
      transition: all $time;
      &:hover {
        color: $basecolor;
      }
      &:after {
        color: $textcolor-light;
        vertical-align: middle;
      }
    }
    & > .dropdown-menu {
      padding: 0;
      border: none;
      background-color: $greybg;
      .dropdown-item {
        &:hover {
          background-color: $basecolor;
          .fas,
          .svg-inline--fa {
            color: #fff;
          }
        }
      }
    }
  }
}

.dropdown-menu {
  .dropdown-item.router-link-exact-active {
    &:hover {
      color: #fff !important;
    }
  }
}

.btn-mobile {
  display: none !important;
  @include r(1199) {
    display: inline-block !important;
  }
}
.current-org {
  display: none;
  font-size: 14px;
  font-weight: 500;
  align-items: center;
  margin-right: 1rem;
  img {
    width: 24px;
    margin-right: 6px;
    margin-top: -1px;
  }
  @include r(1199) {
    display: flex;
  }
}
.dropdown-user-mobile::v-deep {
  display: none;
  @include r(1199) {
    display: inline-block;
  }
  .dropdown-toggle {
    padding: 9px 0 !important;
  }

  .dropdown-menu {
    width: fit-content;
    max-width: calc(100vw - 2rem);

    @include r(1199) {
      left: 0;
    }
  }

  .dropdown-item {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.navbar-collapse.navbar-collapse {
  @include r(1199) {
    display: none !important;
    .navbar-nav {
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;
      width: 100%;
      li {
        display: block;
      }
    }
    &.show {
      display: block !important;
      flex-basis: 100%;
    }
  }
}
.navbar-toggler {
  @include r(1300) {
    padding: 0;
    display: block;
  }
}
.navbar.navbar {
  @include r(1300) {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    flex-flow: row wrap;
  }
}

.mobile-top {
  display: flex;
  align-items: center;

  @include r(1300) {
    width: 100%;
  }
  .nav-item {
    list-style: none;
  }
}

.mobile-top {
  @include r(1300) {
    justify-content: space-between;
  }
}

.change-button > div {
  display: flex;
  align-items: center;

  @include r(1199) {
    display: none;
  }
  @include rm(1199) {
    padding-right: 0.5rem;
    border-right: 1px solid lightgrey;
  }
  .title {
    font-weight: 500;
    margin-left: 1rem;
  }

  .btn {
    min-width: 105px;
  }
}

/* Will only show locations nav item when on mobile */
.change-building-mobile {
  display: none;
  @include r(1199) {
    display: block;
  }
}

.change-building {
  height: 101px;
  width: 100px;
  background-color: $basecolor;
  color: #fff;
  margin-top: -15px;
  margin-bottom: -15px;
  border-radius: 0;
  margin-right: -16px;
  padding: 9px 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  transition: all 0.3s;
  font-size: 24px;
  justify-content: center;
  @include r(1199) {
    display: none;
  }

  &:hover {
    background-color: darken($basecolor, 5);
    text-decoration: none;
  }
}
.dropdown-building::v-deep {
  @include r(1199) {
    display: none;
  }
  .dropdown-toggle {
    height: 101px;
    /*width: 100px;*/
    background-color: $basecolor;
    color: #fff;
    /*font-size: 0;*/
    margin-top: -15px;
    margin-bottom: -15px;
    border-radius: 0;
    margin-right: -16px;
    padding: 9px 10px;
    font-weight: 600;

    svg {
      /*width: 26px;*/
      height: 0px;
    }
    &:hover {
      background-color: darken($basecolor, 5);
    }
  }
  & > .dropdown-menu {
    padding: 0;
    border: none;
    background-color: $greybg;

    .btn {
      width: 100%;
      margin-bottom: -5px;
      border-radius: 0 0 5px 5px;
    }

    .dropdown-item {
      background: transparent;
      .fas,
      .svg-inline--fa {
        color: $textcolor-light;
        display: inline-block;
        vertical-align: middle;
        margin-right: 6px;
        transition: all $time;
      }
      .fa-key {
        font-size: 13px;
      }
      a {
        text-decoration: none;
        color: inherit;
      }
      &:hover {
        background-color: $basecolor;
        .fas,
        .svg-inline--fa {
          color: #fff;
        }
      }
    }
  }
}

.mainmenu {
  a {
    color: $textcolor;
    font-weight: 600;
    text-transform: uppercase;
  }
  .current {
    a {
      color: $basecolor;
    }
  }
}
.navbar-light {
  background-color: #fff;
}
.logo-top {
  max-height: 60px;
  min-height: 30px;
  @include r(1199) {
    max-height: 50px;
  }
}
.custom_nav_item a {
  color: red !important;
  font-size: 15px;
}
</style>
