import OkkuApi from '@/services/OkkuApi'
import moment from 'moment-timezone'
import * as mutations from './mutationTypes'

const actions = {
  async loadSettings({ commit }, payload) {
    const settings = await OkkuApi.getSettings(payload)

    commit(mutations.SET_SETTINGS, settings)
  },

  async loadFloors({ commit }, payload) {
    const floors = await OkkuApi.getMyFloors(payload)

    commit(mutations.SET_FLOORS, floors)
  },

  async loadRooms({ commit }, payload) {
    const rooms = await OkkuApi.getRooms(payload)

    commit(mutations.SET_ROOMS, rooms)
  },

  async loadDesks({ commit }, payload) {
    const desks = await OkkuApi.getDesks(payload)

    commit(mutations.SET_DESKS, desks)
  },

  setTimepickerState({ commit }, payload) {
    const { startTime, endTime, date } = payload
    const formattedDate = moment(date).format(
      'YYYY-MM-DDTHH:mm:ss.SSS'
    )
    commit(mutations.SET_TIMEPICKER_STATE, {
      startTime,
      endTime,
      formattedDate
    })
  },

  async updateOrganization({ commit, dispatch, state }, orgPath) {
    commit(mutations.SET_ORGANISATION_NAME, orgPath)

    if (orgPath && state.authenticated) {
      commit(mutations.SET_READY, false)
      dispatch('loadSettings', orgPath)
      dispatch('loadFloors', orgPath)
      dispatch('loadRooms', orgPath)
      dispatch('loadDesks', orgPath)
      commit(mutations.SET_READY, true)
    } else {
      commit(mutations.SET_EMPTY_ASSETS)
    }
  }
}

export default actions
