import {
  actions,
  getters,
  initialState,
  mutations
} from './attributes'

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
}
