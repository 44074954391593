import {
  SET_ATTRIBUTES,
  SET_ATTRIBUTES_API_MESSAGE,
  SET_ATTRIBUTES_API_RESPONSE_CODE
} from '../common/mutationTypes'

export const initialState = {
  attributes: [],
  attributeApiMessage: '',
  attributeApiResponseCode: ''
}

// getters
export const getters = {
  allAttributes: state =>
    state.attributes.length
      ? state.attributes.map(attribute => {
          attribute.selected = false
          return attribute
        })
      : [],
  getAttributeApiMessage: state => state.attributeApiMessage,
  getAttributeApiResponseCode: state => state.attributeApiResponseCode
}

// actions
export const actions = {
  // Sets attributes based on response of Okku API
  setAttributes({ commit }, payload) {
    commit(SET_ATTRIBUTES, payload)
  },
  // Set response api message based on response of Okku API
  setApiMessage({ commit }, message) {
    commit(SET_ATTRIBUTES_API_MESSAGE, message)
  },
  // Set response api code based on response of Okku API
  setApiResponseCode({ commit }, message) {
    commit(SET_ATTRIBUTES_API_RESPONSE_CODE, message)
  }
}

// mutations
export const mutations = {
  [SET_ATTRIBUTES](state, attributes) {
    state.attributes = attributes
  },
  [SET_ATTRIBUTES_API_MESSAGE](state, message) {
    state.attributeApiMessage = message
  },
  [SET_ATTRIBUTES_API_RESPONSE_CODE](state, message) {
    state.attributeApiResponseCode = message
  }
}
