<template>
  <!--
  To change background color, add class "green" or "orange" to "mobile-confirmation" block
  -->
  <div
    class="mobile-confirmation"
    :class="{
      hidden: busy,
      green: state == 'on-time',
      orange: state == 'late'
    }"
  >
    <div v-if="reservation" class="mobile-confirmation--inner">
      <h1 class="mobile-confirmation--title">
        {{ title }}
      </h1>
      <div class="time-block">
        <div class="mobile-confirmation--subtitle">
          {{
            $t('entranceAllowed', {
              org: organisation.displayName
            })
          }}
        </div>
        <div class="time-row week">
          <span
            v-for="(day, index) in dayAbbreviations"
            :key="index + day"
            class="num wa"
            :class="{
              current: reservation.weekday === index
            }"
            >{{ day }}</span
          >
        </div>
        <div class="time-row">
          <span
            v-for="(char, index) in entranceStartTime.format('HH:mm')"
            :key="'entrance-start-' + index"
            :class="{
              num: char != ':',
              sep: char == ':'
            }"
            >{{ char }}</span
          >
          <span class="sep2 db">{{ $t('until') }}</span>
          <span
            v-for="(char, index) in entranceEndTime.format('HH:mm')"
            :key="'entrance-end-' + index"
            :class="{
              num: char != ':',
              sep: char == ':'
            }"
            >{{ char }}</span
          >
        </div>
      </div>

      <div class="time-block">
        <div class="mobile-confirmation--subtitle small">
          <!--{{ $t("reservationSubtitle", {org: organisation.displayName, floor: floor.name, room: room.name, desk: desk.name}) }}-->
          {{
            $t('reservationSubtitle1', {
              floor: floor.name
            })
          }}
          - <br /><span class="desk-bg">{{
            desk.name || room.name
          }}</span
          ><br />
          {{
            $t('reservationSubtitle2', {
              org: organisation.displayName
            })
          }}
        </div>
        <div class="time-row smaller">
          <span
            v-for="(char, index) in startTime.format('HH:mm')"
            :key="'arrive-start-' + index"
            :class="{
              num: char != ':',
              sep: char == ':'
            }"
            >{{ char }}</span
          >
          <span class="sep2">-</span>
          <span
            v-for="(char, index) in endTime.format('HH:mm')"
            :key="'arrive-end-' + index"
            :class="{
              num: char != ':',
              sep: char == ':'
            }"
            >{{ char }}</span
          >
        </div>
        <p>
          <span v-if="startsIn"
            >{{ $t('startsIn') }} - {{ startsIn }}<br
          /></span>
          <span v-if="lastName"
            >{{ $t('name') }} - <strong>{{ lastName }}</strong></span
          >
        </p>
      </div>
    </div>
    <div v-if="!reservation" class="mobile-confirmation--inner">
      <h1 class="mobile-confirmation--title">
        {{ $t('reservationNotFound') }}
      </h1>
    </div>
    <div class="logo">
      <img
        :src="organisation.logoUrl || '@/assets/img/okkuLogo.png'"
        height="30px"
        alt
      />
    </div>
  </div>
</template>

<script>
import i18n from '@/plugins/i18n'
import OkkuApi from '@/services/OkkuApi'
import moment from 'moment-timezone'
import _get from 'lodash/get'

export default {
  name: 'MobileConfirmation',
  data() {
    return {
      busy: true,
      dayAbbreviations: [],
      reservation: null,
      organisation: {},
      floor: {},
      room: {},
      desk: {},
      startTime: moment(),
      endTime: moment(),
      entranceStartTime: moment(),
      entranceEndTime: moment(),
      startsIn: '',
      lastName: '',
      interval: null,
      state: 'early'
    }
  },
  computed: {
    title() {
      if (
        this.reservation &&
        this.reservation.context.checkInDatetime
      ) {
        return this.$t('checkedIn')
      }
      if (this.state === 'on-time') {
        return this.$t('reservationOnTime')
      }
      if (this.state === 'late') {
        return this.$t('reservationLate')
      }
      return this.$t('reservationEarly')
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  created() {
    i18n.locale = 'nl'
    this.$cookie.set('lang', i18n.locale, 30)
    moment.locale('nl')
    this.dayAbbreviations = this.$t('dayAbbreviations')
    OkkuApi.getReservationByHash(this.$route.params.hash).then(
      data => {
        if (!_get(data, 'reservation')) {
          this.busy = false
          return
        }
        this.reservation = data.reservation
        this.organisation = data.organisation
        this.floor = data.floor || {}
        this.room = data.room || {}
        this.desk = data.desk || {}
        this.reservation.weekday =
          moment(this.reservation.start_datetime).isoWeekday() - 1
        this.startTime = moment(this.reservation.start_datetime)
        this.entranceStartTime = this.startTime
          .clone()
          .subtract(
            _get(
              data,
              'organisation.reservationSettings.earlyEntryMinutes',
              15
            ),
            'minutes'
          )
        this.endTime = moment(this.reservation.end_datetime)
        this.entranceEndTime = moment(this.endTime)
        this.refreshTimes()
        this.busy = false
        this.interval = setInterval(this.refreshTimes, 10000)
        this.lastName = _get(data, 'reservation.context.lastName', '')
      }
    )
  },
  methods: {
    refreshTimes() {
      if (moment().isBefore(this.startTime)) {
        this.startsIn = moment
          .duration(moment().diff(this.startTime))
          .humanize()
      } else {
        this.startsIn = ''
      }
      if (
        moment().isAfter(this.entranceStartTime) &&
        moment().isBefore(this.entranceEndTime)
      ) {
        this.state = 'on-time'
      } else if (moment().isAfter(this.entranceEndTime)) {
        this.state = 'late'
      } else {
        this.state = 'early'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../assets/scss/globals/vars.scss';
@import '../assets/scss/globals/mixins.scss';
@import '../assets/scss/globals/extend.scss';

$red: #ed4a72;
$redDark: #b51324;
$green: #4ab7a3;
$greenDark: #317d7d;
$orange: #f69c39;
$orangeDark: #b96406;

$red2: red;
$green2: green;
$orange2: #ea6d00;

.mobile-confirmation {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  background-color: #fff;
  &--inner {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 60px;
    text-align: center;
  }

  &--title {
    font-weight: 700;
    font-size: 52px;
    margin-bottom: 30px;
    color: $red2;
    letter-spacing: 0.6px;
    @include rm(350) {
      font-size: 63px;
    }
    @include rm(767) {
      font-size: 116px;
      margin-bottom: 60px;
    }
  }
  &--subtitle {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0.6px;
    margin-bottom: 10px;
    &.small {
      font-size: 16px;
      width: calc(100% + 32px);
      margin-left: -16px;
      padding: 9px 10px;
      @include rm(350) {
        font-size: 16px;
      }
      @include rm(767) {
        font-size: 26px;
      }
    }
    @include rm(350) {
      font-size: 21px;
    }
    @include rm(767) {
      font-size: 52px;
    }
  }

  .time-block {
    margin-bottom: 25px;
    padding: 0 16px;
    @include rm(767) {
      margin-bottom: 40px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  .time-row {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }

    .num {
      background-color: $red2;
      border-radius: 8px;
      font-size: 22px;
      width: 28px;
      color: #fff;
      height: 40px;
      text-align: center;
      line-height: 40px;
      font-weight: 700;

      margin: 0 1px;
      box-shadow: 1px 1px 2px 0px rgba(#000, 0.15);
      &.wa {
        width: auto;
        padding: 0 15px;
      }
      @include rm(350) {
        width: 46px;
        font-size: 42px;
        line-height: 56px;
        height: 56px;
      }
      @include rm(767) {
        width: 64px;
        height: 84px;
        font-size: 42px;
        line-height: 84px;
        margin: 0 2px;
      }
    }
    .sep {
      font-size: 26px;
      margin: 0 1px;
      @include rm(767) {
        font-size: 32px;
      }
    }
    .sep2 {
      margin: 0 4px;
      font-size: 21px;
      &.db {
        display: block;
        text-align: center;
        width: 100%;
        font-size: 26px;
        font-weight: bold;
        margin: 4px 0;
      }
      @include rm(767) {
        font-size: 32px;
      }
    }

    &.week {
      .num {
        background-color: #fff;
        color: #000;
        text-transform: uppercase;
        font-size: 16px;
        padding: 0 6px;
        @include rm(375) {
          font-size: 21px;
        }
        @include rm(767) {
          padding: 0 15px;
        }
        margin: 2px;
        border: 1px solid #000;
        &.current {
          background-color: $red2;
          color: #fff;
          border-color: $red2;
        }
      }
    }
    &.smaller {
      .num {
        @include rm(350) {
          font-size: 24px;
          height: 40px;
          line-height: 40px;
          width: 30px;
        }
      }
    }
  }

  .logo {
    position: fixed;
    bottom: 0px;
    text-align: center;
    left: 0;
    padding-bottom: 15px;
    background-color: #fff;
    opacity: 1;
    width: 100%;
    height: 50px;
  }

  .desk-bg {
    display: inline-block;
    color: white;
    background: $red2;
    width: auto;
    padding: 2px 8px;
    border-radius: 4px;
    margin: 4px 0;
  }

  &.green {
    .mobile-confirmation--title {
      color: $green2;
    }
    .mobile-confirmation--subtitle {
      .desk-bg {
        background-color: $green2;
      }
    }
    .time-row {
      .num {
        background-color: $green2;
      }
      &.week {
        .num {
          background-color: #fff;
          &.current {
            background-color: $green2;
            border-color: $green2;
          }
        }
      }
    }
  }
  &.orange {
    .mobile-confirmation--title {
      color: $orange2;
    }
    .mobile-confirmation--subtitle {
      .desk-bg {
        background-color: $orange2;
      }
    }
    .time-row {
      .num {
        background-color: $orange2;
      }
      &.week {
        .num {
          background-color: #fff;
          &.current {
            background-color: $orange2;
            border-color: $orange2;
          }
        }
      }
    }
  }
  p {
    font-size: 21px;
    color: rgba(#000, 0.5);
  }
}
</style>
