const runWithTimeout = ({
  condition,
  callback,
  interval = 1000,
  maxAttempts = 10
}) => {
  let attempts = 0

  const checkAndContinue = async () => {
    if (await condition()) {
      callback()
    } else if (attempts < maxAttempts) {
      setTimeout(checkAndContinue, interval)
      // eslint-disable-next-line no-plusplus
      attempts++
    } else {
      // eslint-disable-next-line no-console
      console.log('Condition not met after max attempts.')
    }
  }

  checkAndContinue()
}

export default runWithTimeout
