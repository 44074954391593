var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mobile-confirmation",class:{
    hidden: _vm.busy,
    green: _vm.state == 'on-time',
    orange: _vm.state == 'late'
  }},[(_vm.reservation)?_c('div',{staticClass:"mobile-confirmation--inner"},[_c('h1',{staticClass:"mobile-confirmation--title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"time-block"},[_c('div',{staticClass:"mobile-confirmation--subtitle"},[_vm._v(" "+_vm._s(_vm.$t('entranceAllowed', { org: _vm.organisation.displayName }))+" ")]),_c('div',{staticClass:"time-row week"},_vm._l((_vm.dayAbbreviations),function(day,index){return _c('span',{key:index + day,staticClass:"num wa",class:{
            current: _vm.reservation.weekday === index
          }},[_vm._v(_vm._s(day))])}),0),_c('div',{staticClass:"time-row"},[_vm._l((_vm.entranceStartTime.format('HH:mm')),function(char,index){return _c('span',{key:'entrance-start-' + index,class:{
            num: char != ':',
            sep: char == ':'
          }},[_vm._v(_vm._s(char))])}),_c('span',{staticClass:"sep2 db"},[_vm._v(_vm._s(_vm.$t('until')))]),_vm._l((_vm.entranceEndTime.format('HH:mm')),function(char,index){return _c('span',{key:'entrance-end-' + index,class:{
            num: char != ':',
            sep: char == ':'
          }},[_vm._v(_vm._s(char))])})],2)]),_c('div',{staticClass:"time-block"},[_c('div',{staticClass:"mobile-confirmation--subtitle small"},[_vm._v(" "+_vm._s(_vm.$t('reservationSubtitle1', { floor: _vm.floor.name }))+" - "),_c('br'),_c('span',{staticClass:"desk-bg"},[_vm._v(_vm._s(_vm.desk.name || _vm.room.name))]),_c('br'),_vm._v(" "+_vm._s(_vm.$t('reservationSubtitle2', { org: _vm.organisation.displayName }))+" ")]),_c('div',{staticClass:"time-row smaller"},[_vm._l((_vm.startTime.format('HH:mm')),function(char,index){return _c('span',{key:'arrive-start-' + index,class:{
            num: char != ':',
            sep: char == ':'
          }},[_vm._v(_vm._s(char))])}),_c('span',{staticClass:"sep2"},[_vm._v("-")]),_vm._l((_vm.endTime.format('HH:mm')),function(char,index){return _c('span',{key:'arrive-end-' + index,class:{
            num: char != ':',
            sep: char == ':'
          }},[_vm._v(_vm._s(char))])})],2),_c('p',[(_vm.startsIn)?_c('span',[_vm._v(_vm._s(_vm.$t('startsIn'))+" - "+_vm._s(_vm.startsIn)),_c('br')]):_vm._e(),(_vm.lastName)?_c('span',[_vm._v(_vm._s(_vm.$t('name'))+" - "),_c('strong',[_vm._v(_vm._s(_vm.lastName))])]):_vm._e()])])]):_vm._e(),(!_vm.reservation)?_c('div',{staticClass:"mobile-confirmation--inner"},[_c('h1',{staticClass:"mobile-confirmation--title"},[_vm._v(" "+_vm._s(_vm.$t('reservationNotFound'))+" ")])]):_vm._e(),_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":_vm.organisation.logoUrl || '@/assets/img/okkuLogo.png',"height":"30px","alt":""}})])])}
var staticRenderFns = []

export { render, staticRenderFns }