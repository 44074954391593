<template>
  <div class="page-container">
    <section class="section-settings">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-lg-12 col-sm-12">
            <b-alert
              dismissible
              fade
              :variant="alertVariant"
              :show="dismissCountDown"
              @dismiss-count-down="countDownChanged"
            >
              {{ alertText }}
            </b-alert>
            <div class="title-wrap">
              <h1 class="title-1">
                {{ $t('myAbsences') }}
              </h1>
            </div>
            <AbsencesTable
              :email="userInfo.user"
              :display-name="userInfo.userDisplayName"
              @updateAlert="updateAlert"
              @updateAbsences="updateAbsences"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import AbsencesTable from '@/components/common/AbsencesTable'
import store from '@/store'
import OkkuApi from '@/services/OkkuApi'
import { mapState } from 'vuex'

export default {
  name: 'MyAbsencesPage',
  components: {
    AbsencesTable
  },
  data() {
    return {
      dismissCountDown: 0,
      alertVariant: 'success',
      alertText: ''
    }
  },
  computed: {
    ...mapState('common', {
      userInfo: '$userInfo'
    })
  },
  beforeCreate() {
    if (!store.getters['common/reservationsEnabled']) {
      this.$router.push('/')
    }
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    updateAlert(args) {
      this.dismissCountDown = 3
      this.alertVariant = args.variant
      this.alertText = args.text
    },
    async updateAbsences(absences) {
      await OkkuApi.createAbsence(this.userInfo.user, absences)
      this.updateAlert({
        variant: 'success',
        text: this.$t('changesSaved')
      })
    }
  }
}
</script>
