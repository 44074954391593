const createRandomToken = (len = 12) => {
  const date = new Date().getTime().toString()
  const token = Array.from({ length: len }, () =>
    date.charAt(Math.floor(Math.random() * date.length))
  ).join('')

  return token
}

export default createRandomToken
