<template>
  <div class="page-container">
    <section class="section-settings">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-lg-12 col-sm-12">
            <h1>
              You are not assigned to an organisation
            </h1>
            <div class="mt-4">
              Your account has been created, but you still need to be
              added to the correct organisation.

              <ul class="mt-4">
                <li>
                  If you created an account through a magic link and
                  are still seeing this screen, go to
                  <b-link href="https://support.okku.io"
                    >https://support.okku.io</b-link
                  >
                  and follow the instructions for magic links on our
                  FAQ page
                </li>
                <li v-if="supportEmail">
                  Otherwise, contact your administrator to add you to
                  the appropriate building(s) at: {{ supportEmail }}
                </li>
                <li v-if="!supportEmail">
                  Otherwise, contact your administrator to add you to
                  the appropriate building(s).
                </li>
              </ul>
              <div>
                <b-btn class="mt-3" variant="primary" @click="home"
                  >Try again</b-btn
                >
              </div>
              <div class="mt-4 small">
                (You may need to login again for the changes to be
                reflected)
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import OkkuApi from '@/services/OkkuApi'

export default {
  name: 'NoAccessPage',
  data() {
    return {
      supportEmail: null
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!this.hasOrganisationAccess) {
      next(false)
    }
  },
  computed: {
    ...mapGetters('common', {
      hasOrganisationAccess: 'hasOrganisationAccess'
    })
  },
  mounted() {
    this.getSupportEmail()
  },
  methods: {
    home(event) {
      window.location.href = '/workplace'
    },
    async getSupportEmail() {
      const domainInfo = await OkkuApi.getDomainInformation(
        this.$route.params
      )
      // If supportEmail has been found, show it
      if (domainInfo && domainInfo.supportEmail) {
        this.supportEmail = domainInfo.supportEmail
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
