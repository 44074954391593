<template>
  <div class="page-container">
    <section class="section-settings">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-lg-12 col-sm-12">
            <b-alert
              dismissible
              fade
              :variant="alertVariant"
              :show="dismissCountDown"
              @dismiss-count-down="countDownChanged"
            >
              {{ alertText }}
            </b-alert>
            <AllReservationsTable @updateAlert="updateAlert" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import AllReservationsTable from '@/components/common/AllReservationsTable'

export default {
  name: 'AllReservationsPage',
  components: {
    AllReservationsTable
  },
  data() {
    return {
      dismissCountDown: 0,
      alertVariant: 'success',
      alertText: ''
    }
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    updateAlert(args) {
      this.dismissCountDown = 3
      this.alertVariant = args.variant
      this.alertText = args.text
    }
  }
}
</script>
<style lang="scss" scoped></style>
