<template>
  <nav aria-label="Page navigation">
    <ul class="pagination">
      <li class="page-item" :class="{ disabled: currentPage === 1 }">
        <a
          v-if="!disableArrows"
          class="page-link"
          href="#"
          aria-label="Previous"
          @click.prevent="changePage(currentPage - 1)"
        >
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>
      <li
        v-for="page in totalPages"
        :key="page"
        class="page-item"
        :class="{ active: currentPage === page }"
      >
        <a
          class="page-link"
          :style="{
            backgroundColor:
              currentPage === page ? activeItemColor : 'white'
          }"
          href="#"
          @click.prevent="changePage(page)"
          >{{ page }}</a
        >
      </li>
      <li
        class="page-item"
        :class="{ disabled: currentPage === totalPages }"
      >
        <a
          v-if="!disableArrows"
          class="page-link"
          href="#"
          aria-label="Next"
          @click.prevent="changePage(currentPage + 1)"
        >
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number,
      required: true
    },
    totalPages: {
      type: Number,
      required: true
    },
    disableArrows: {
      type: Boolean,
      default: false
    },
    activeItemColor: {
      type: String,
      required: true
    }
  },
  methods: {
    changePage(page) {
      if (
        page !== this.currentPage &&
        page > 0 &&
        page <= this.totalPages
      ) {
        this.$emit('page-changed', page)
      }
    }
  }
}
</script>

<style scoped>
.page-item.disabled .page-link {
  pointer-events: none;
  cursor: default;
}
</style>
