import {
  SET_IS_AUTHENTICATED,
  SET_IS_KIOSK,
  SET_KEYCLOAK_USER_PROFILE
} from '@/store/modules/common/mutationTypes'

import Keycloak from 'keycloak-js'
import axios from 'axios'
import { keycloackClientRoles } from '@/constants'
import store from '@/store'

axios.defaults.headers.common.Authorization = ''

class KeycloackAuth {
  constructor() {
    const host =
      process.env.VUE_APP_OKKU_API_HOST ||
      `${window.location.protocol}//${window.location.host}`
    this.keycloak = new Keycloak(`${host}/keycloak.json`)
    this.options = {
      onLoad: 'login-required',
      checkLoginIframe: false
    }
    this.tokenRefreshIntervalId = 0

    store.commit(`common/${SET_IS_AUTHENTICATED}`, false)
  }

  isAdminOrCoordinator() {
    return (
      this.keycloak.hasResourceRole(
        keycloackClientRoles.dashboardAdmin
      ) ||
      this.keycloak.hasResourceRole(
        keycloackClientRoles.customerAdmin
      ) ||
      this.keycloak.hasResourceRole(
        keycloackClientRoles.customerCoordinator
      )
    )
  }

  isAdmin() {
    return (
      this.keycloak.hasResourceRole(
        keycloackClientRoles.dashboardAdmin
      ) ||
      this.keycloak.hasResourceRole(
        keycloackClientRoles.customerAdmin
      )
    )
  }

  isDashboardAdmin() {
    return this.keycloak.hasResourceRole(
      keycloackClientRoles.dashboardAdmin
    )
  }

  setUserProfile() {
    this.keycloak
      .loadUserProfile()
      .success(profile => {
        store.commit(`common/${SET_KEYCLOAK_USER_PROFILE}`, profile)
      })
      .error(error => {
        console.log(error)
      })
  }

  updateKeycloakToken(minValidity = 5) {
    this.keycloak
      .updateToken(minValidity)
      .success(refreshed => {
        if (refreshed) {
          axios.defaults.headers.common.Authorization = `Bearer ${this.keycloak.token}`
          this.setUserProfile()
        } else {
          console.log('Token is still valid')
        }
      })
      .error(() => {
        this.keycloak.clearToken()
        clearInterval(this.tokenRefreshIntervalId)

        window.location.href = '/logout'
      })
  }

  authSuccess() {
    axios.defaults.headers.common.Authorization = `Bearer ${this.keycloak.token}`
    axios.defaults.withCredentials = true
    this.setUserProfile()
    if (window.location.href.includes('kiosk=true')) {
      sessionStorage.setItem('kiosk', true)
      store.commit(`common/${SET_IS_KIOSK}`, true)
    }
    this.authenticated = true
    store.commit(`common/${SET_IS_AUTHENTICATED}`, true)

    const tokenValidityPeriod =
      this.keycloak.tokenParsed.exp -
      Math.ceil(new Date().getTime() / 1000) +
      this.keycloak.timeSkew

    const preemptiveGap = 60
    this.tokenRefreshIntervalId = setInterval(() => {
      this.updateKeycloakToken(tokenValidityPeriod)
    }, (tokenValidityPeriod - preemptiveGap) * 1000)
  }

  init(next, idpHint) {
    const kcLogin = this.keycloak?.login
    this.keycloak.login = options => {
      options.idpHint = null

      if (idpHint) {
        options.idpHint = idpHint
      }
      kcLogin(options)
    }

    this.keycloak
      .init(this.options)
      .success(authenticated => {
        if (authenticated) {
          this.authSuccess()
          next()
        } else {
          next({ name: 'Unauthorized' })
        }
      })
      .error(e => {
        console.log('failed to initialize', e)
        next({ name: 'Unauthorized' })
      })
  }
}

const keycloackAuth = new KeycloackAuth()
export default keycloackAuth
