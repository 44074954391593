<template>
  <div class="page-container">
    <b-img-lazy fluid src="/assets/img/okkuHeader.png"></b-img-lazy>
    <section class="section-settings">
      <div class="container center">
        <div>
          <h2 class="mb-0" align="center">{{ $t('sensorData') }}</h2>
          <b-container align="center">
            <b-row>
              <b-col
                ><small>
                  {{ $t('lastMessage') }}
                  {{ datetime }}
                </small></b-col
              >
            </b-row>
          </b-container>
          <b-container class="table-style mt-4 " align="center">
            <b-row>
              <b-col
                ><span class="column display-3">{{
                  sensorData.co2
                }}</span
                ><span class="display-5">
                  {{ $t('ppm') }}</span
                ></b-col
              >
            </b-row>
            <b-row>
              <b-col>CO<sup>2</sup></b-col></b-row
            >
          </b-container>

          <b-container align="center" class="table-style">
            <b-row>
              <b-col
                ><span class="display-3">{{
                  sensorData.temperature
                }}</span
                ><sup><span class="display-5"> °C</span></sup></b-col
              >
            </b-row>
            <b-row>
              <b-col>{{ $t('temperature') }}</b-col></b-row
            >
          </b-container>

          <b-container align="center" class="table-style">
            <b-row>
              <b-col
                ><span class="table-settings display-3">{{
                  sensorData.humidity
                }}</span
                ><span class="display-5"> %</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                ><span class="body">{{ $t('humidity') }}</span></b-col
              ></b-row
            >
          </b-container>

          <b-container class="mt-4" align="center">
            <b-row align="center">
              <b-col class="col-3"></b-col>
              <b-col class="col-6"
                ><b-button
                  href="/workplace"
                  block
                  variant="primary"
                  class="mr-3"
                  >{{ $t('loginForMoreInfo') }}
                </b-button>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import OkkuApi from '@/services/OkkuApi'
import moment from 'moment'

export default {
  name: 'SensorPage',
  data() {
    return {
      sensorData: {
        co2: '--',
        temperature: '--',
        humidity: '--'
      },
      externalId: this.$route.params.id
    }
  },
  computed: {
    datetime() {
      if (this.sensorData.datetime) {
        return moment().to(moment(this.sensorData.datetime))
      }

      return '--'
    }
  },
  mounted() {
    this.getSensorData()
    this.timer = setInterval(this.getSensorData, 1000 * 30)
  },
  destroyed() {
    clearInterval(this.timer)
  },
  methods: {
    home(event) {
      window.location.href = '/workplace'
    },
    async getSensorData() {
      const sensorData = await OkkuApi.getSensorData(this.externalId)
      this.sensorData = sensorData
    }
  }
}
</script>

<style lang="scss" scoped></style>
