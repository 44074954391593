import _pick from 'lodash/pick'

import {
  SET_DESKS,
  SET_FLOORS,
  SET_ROOMS,
  SET_SETTINGS,
  SET_EMPTY_ASSETS
} from './mutationTypes'

const mutations = {
  [SET_EMPTY_ASSETS](state) {
    state.floors = []
    state.desks = []
    state.rooms = []
  },

  [SET_SETTINGS](state, settings) {
    state.$settings = settings
    state.organisationReservationSettings =
      settings.reservationSettings
    state.organisationModules = settings.modules
    state.organisationUrls = _pick(settings, ['logoUrl', 'infoUrl'])
    state.dashboardSettings = {
      ...settings.dashboardSettings,
      hasSensors: settings.hasSensors
    }
  },
  [SET_FLOORS](state, floors) {
    state.floors = floors
  },
  [SET_ROOMS](state, rooms) {
    state.rooms = rooms
  },
  [SET_DESKS](state, desks) {
    state.desks = desks
  }
}

export default mutations
