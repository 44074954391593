import { SET_RESERVATIONS, RESET_MODULE_STATE } from './mutationTypes'
import { getDefaultState } from './state'

const mutations = {
  [SET_RESERVATIONS](state, payload) {
    state.reservations = payload
  },

  [RESET_MODULE_STATE](state) {
    Object.assign(state, getDefaultState())
  }
}

export default mutations
