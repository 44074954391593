function loadModules() {
  const context = require.context('./', true, /([a-z_]+)\.js$/i)

  const modules = context
    .keys()
    .filter(
      filename =>
        filename.includes('/index') && filename !== './index.js'
    )
    .map(key => ({ key, name: key.split('/')[1] }))
    .reduce(
      (_modules, { key, name }) => ({
        ..._modules,
        [name]: context(key).default
      }),
      {}
    )

  return { context, modules }
}

const { context, modules } = loadModules()

if (module.hot) {
  // Hot reload whenever any module changes.
  module.hot.accept(context.id, () => {
    const { modules: _modules } = loadModules()

    // eslint-disable-next-line global-require
    require('..').default.hotUpdate({
      _modules
    })
  })
}

export default modules
