<template>
  <div class="page-container">
    <div class="login-container-inner tac">
      <b-img-lazy fluid :src="logoUrl" class="logo pb-3"></b-img-lazy>
      <h1 class="mb-3">
        {{ $t('loginToReserveDesk') }}
      </h1>
      <b-button id="loginUrl" href="/workplace" variant="primary">{{
        $t('login')
      }}</b-button>
      <div class="mt-4">
        <p class="small">
          <a class="mr-1" :href="infoUrl" target="_blank">{{
            $t('support')
          }}</a>
          <a
            target="_blank"
            class="ml-3"
            href="https://okku.io/jobs/"
            >{{ $t('jobs') }}</a
          >
        </p>
        <p class="small">
          <a href="https://www.okku.io"
            >{{ $t('allRightsReserved') }}, OKKU B.V.</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import OkkuApi from '@/services/OkkuApi'
import i18n from '@/plugins/i18n'
import moment from 'moment'

export default {
  name: 'StartPage',
  data() {
    return {
      logoUrl: '',
      idpHint: '',
      infoUrl: ''
    }
  },
  mounted() {
    const lang = this.$cookie.get('lang') || 'en'
    if (lang) {
      i18n.locale = lang
      moment.locale(this.$i18n.locale)
    }
    this.getDomainInformation()
  },
  methods: {
    async getDomainInformation() {
      try {
        console.log(JSON.stringify(this.$route.params))
        const {
          idpHint,
          logoUrl,
          infoUrl
        } = await OkkuApi.getDomainInformation(this.$route.params)
        this.infoUrl =
          infoUrl ||
          'https://okku.atlassian.net/servicedesk/customer/portals'
        this.idpHint = idpHint
        this.logoUrl =
          logoUrl ||
          'https://static.okku.io/assets/okku-logo-2021.svg'
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container:before {
  content: ' ';
  background-image: url(~@/assets/img/okkuLoginBackground-min.jpg);
  background-size: cover;
  justify-content: center;
  display: flex;
  padding: 50px 40px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  box-sizing: border-box;
}

.page-container .login-container-inner {
  position: relative;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 50px 50px 30px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  width: 500px;
  margin: auto;
}

body {
  font-family: 'Poppins', sans-serif;
  color: #000;
  overflow-x: hidden;
}

.page-container h1 {
  font-weight: 100;
  font-size: 24px;
  line-height: 1.5;
}

.page-container {
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}
.page-container .login-container-inner .btn {
  min-width: 120px;
}
</style>
