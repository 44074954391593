<template>
  <b-modal
    id="modal-changedate"
    :on-enter-key-press="applyChanges"
    hide-footer
    :title="hideDate ? $t('changeTimeTitle') : $t('changeDateTitle')"
    @hidden="onModalToggle(false)"
    @shown="onModalToggle(true)"
  >
    <div class="custom-dates-wrap">
      <div v-if="!hideDate" class="form-group">
        <label
          >{{ $t('timepickerDate') }}:
          <span
            v-b-tooltip.hover
            class="tip-icon"
            :title="
              $t('timezoneHint', {
                timezone: buildingTimezone
              })
            "
          >
            <font-awesome-icon icon="question-circle" />
          </span>
        </label>
        <div>
          <date-picker
            v-model="selectedDate"
            type="date"
            format="DD MMM YYYY"
            placeholder="Start Date"
            input-class="form-control-sm inp-style"
            popup-class="popup_date-picker"
            :clearable="false"
            :value-type="selectedDateFormat"
            :disabled-date="disabledBeforeTodayAndAfterMaxDate"
            :lang="lang"
          />
        </div>
      </div>
      <template>
        <div
          v-if="timeslotsEnabled && timeslots.length > 0"
          class="form-group"
        >
          <template>
            <label>{{ $t('timeslot') }}:</label>
            <b-form-select v-model="selectedTimeslot">
              <option
                v-for="(timeslot, index) in timeslots"
                :key="`${timeslot}__${index}`"
                :value="timeslot"
              >
                {{ createTimeslotLabel(timeslot) }}
              </option>
            </b-form-select>
          </template>
        </div>
        <div v-if="!timeslotsEnabled" class="row">
          <div class="col-6">
            <div class="form-group">
              <label>{{ $t('startTime') }}:</label>
              <datetime
                id="reservation-start-time-input"
                ref="startTimeRef"
                v-model="startTime"
                class="form-control-sm inp-style"
                type="time"
                placeholder="Start Time"
                :minute-step="15"
                :min-datetime="minStartTime"
                :max-datetime="maxStartTime"
                :zone="buildingTimezone"
                @close="clearApplicationState"
              />
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label>{{ $t('endTime') }}:</label>
              <datetime
                id="reservation-end-time-input"
                ref="endTimeRef"
                v-model="endTime"
                class="form-control-sm inp-style"
                type="time"
                placeholder="End Time"
                :minute-step="15"
                :min-datetime="minEndTime"
                :max-datetime="maxEndTime"
                :zone="buildingTimezone"
                @close="clearApplicationState"
              />
            </div>
          </div>
        </div>
        <template
          v-if="showHints && !timeSelectionInsideOfAllowedTimespan"
        >
          <p style="color:red">
            {{
              $t('timeSelectionOutsideOfAllowedTimespan', {
                startTime: moment(startTime)
                  .tz(buildingTimezone)
                  .format('HH:mm'),
                endTime: moment(endTime)
                  .tz(buildingTimezone)
                  .format('HH:mm')
              })
            }}
          </p>
        </template>
      </template>
      <template v-if="showHints && allAvailableTimeslots.length > 0">
        <p>{{ $t('reservationsAllowed') }}:</p>
        <ul>
          <li
            v-for="(timeslot, idx) in allAvailableTimeslots"
            :key="`${createTimeslotLabel(timeslot)}__${idx}`"
          >
            {{ createTimeslotLabel(timeslot) }}
          </li>
        </ul>
      </template>
      <template v-if="timeslots && timeslots.length === 0">
        <p>{{ $t('timepickerNoTimeslotsAvailable') }}</p>
      </template>
    </div>
    <b-button
      class="mt-3"
      :disabled="
        (timeslots && timeslots.length === 0) ||
          !timeSelectionInsideOfAllowedTimespan
      "
      block
      @click="applyChanges"
    >
      {{ $t('apply') }}
    </b-button>
  </b-modal>
</template>
<script>
import {
  get as _get,
  isEmpty as _isEmpty,
  orderBy as _orderBy
} from 'lodash'
import moment from 'moment-timezone'
import { mapGetters, mapState } from 'vuex'
import { Datetime } from 'vue-datetime'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

import {
  SET_ALERT,
  SET_TIMEPICKER_STATE
} from '@/store/modules/common/mutationTypes'

import BootstrapModalHOC from '@/components/hocs/BootstrapModal'
import { SCROLLER } from '../../constants'

function roundUpMinutes(minutes, denominator) {
  const excess = minutes % denominator

  if (excess > 0) {
    return minutes + denominator - excess
  }
  return minutes
}

const defaultProps = {
  reservationSettings: {
    defaultPeriod: 60,
    timeslot: false,
    timezone: 'Europe/Amsterdam',
    futureDayMaximum: 0
  },
  showHints: false
}

export default {
  components: {
    datetime: Datetime,
    'b-modal': BootstrapModalHOC,
    DatePicker
  },
  props: {
    rawTimeslots: {
      type: Array,
      required: true
    },
    reservationSettings: {
      type: Object,
      default() {
        return defaultProps.reservationSettings
      }
    },
    showHints: {
      type: Boolean,
      default: defaultProps.showHints
    },
    mode: {
      type: String,
      default: 'local'
    },
    navigated: {
      type: Boolean,
      default: false
    },
    navigatedDate: {
      type: String,
      default: moment().toISOString()
    },
    hideDate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const defaultStartTime = moment()
      .tz('UTC')
      .set({
        minute: roundUpMinutes(moment().minute(), 15),
        seconds: 0,
        milliseconds: 0
      })
      .toISOString()

    function defineDefaultEndTime() {
      const endTime = moment(defaultStartTime).add(
        defaultProps.reservationSettings.defaultPeriod,
        'minutes'
      )

      if (endTime.isAfter(defaultStartTime, 'day')) {
        return moment(defaultStartTime)
          .endOf('day')
          .seconds(0)
          .milliseconds(0)
          .toISOString()
      }
      return endTime.toISOString()
    }

    const selectedDateFormat = 'YYYY-MM-DDTHH:mm:ss.sssZ'

    return {
      minStartTime: null,
      maxStartTime: null,
      minEndTime: null,
      maxReservations: null,
      maxEndTime: moment()
        .endOf('day')
        .toISOString(),
      maxDate: null,
      minDate: moment()
        .tz('UTC')
        .startOf('day')
        .toISOString(),
      selectedDate: moment()
        .tz('UTC')
        .startOf('day')
        .format(selectedDateFormat),
      startTime: defaultStartTime,
      endTime: defineDefaultEndTime(),
      timeslots: [],
      allAvailableTimeslots: [],
      selectedTimeslot: null,
      initialized: false,
      moment,
      fixMinStartTime: '',
      fixMaxEndTime: '',
      innerValue: 0,
      isOpen: false,
      selectedDateFormat,
      // datepicker option
      lang: {
        formatLocale: {
          firstDayOfWeek: 1
        }
      }
    }
  },
  computed: {
    ...mapState('common', {
      keycloakUserProfile: 'keycloakUserProfile',
      timepickerGlobalState: 'timepicker'
    }),
    ...mapGetters('common', {
      buildingTimezone: 'buildingTimezone'
    }),
    timeSelectionInsideOfAllowedTimespan() {
      return this.allAvailableTimeslots.length === 0
        ? true
        : this.allAvailableTimeslots.filter(
            ({ startTime, endTime }) =>
              moment(this.startTime).isSameOrAfter(startTime) &&
              moment(this.endTime).isSameOrBefore(endTime) &&
              moment(this.endTime).isAfter(startTime)
          ).length > 0
    },
    minReservationLength() {
      return (
        this.reservationSettings.defaultPeriod ||
        this.reservationSettings.reservationTimeLimit
      )
    },
    timeslotsEnabled() {
      return this.reservationSettings.timeslot
    },
    futureDayMaximum() {
      return _get(
        this.keycloakUserProfile,
        'attributes.futureReservationMaximum[0]',
        this.reservationSettings.futureDayMaximum
      )
    },
    autoUpdateEndTime() {
      return this.isOpen || !this.navigated
    }
  },
  watch: {
    futureDayMaximum() {
      this.setMaxDate()
    },
    selectedTimeslot() {
      if (this.selectedTimeslot) {
        this.startTime = this.selectedTimeslot.startTime
        this.endTime = this.selectedTimeslot.endTime
      }
    },
    selectedDate() {
      if (
        this.timepickerGlobalState.date &&
        this.selectedDate === this.timepickerGlobalState.date
      ) {
        this.applyReservationTimeLimit()
        return
      }
      this.startTime =
        this.selectedDate.substring(0, 10) +
        this.startTime.substring(10)
      this.endTime =
        this.selectedDate.substring(0, 10) +
        this.endTime.substring(10)
      if (this.mode === 'global') {
        this.maxEndTime = moment(this.endTime)
          .tz(moment.tz.guess())
          .endOf('day')
          .seconds(0)
          .milliseconds(0)
          .toISOString()
      }
      this.applyReservationTimeLimit()

      this.$store.commit(`common/${SET_TIMEPICKER_STATE}`, {
        date: this.formatDate(this.selectedDate),
        startTime: this.startTime,
        endTime: this.endTime
      })
    },
    endTime() {
      if (moment(this.endTime).isSameOrBefore(this.startTime)) {
        if (
          !this.timeslotsEnabled &&
          this.reservationSettings.reservationTimeLimit
        ) {
          this.applyReservationTimeLimit()
        } else {
          this.endTime = moment(this.startTime)
            .add(this.reservationSettings.defaultPeriod, 'minutes')
            .toISOString()
        }
      }
    },
    startTime() {
      if (!this.timeslotsEnabled && this.autoUpdateEndTime) {
        this.restrictEndTimeInsideOpeningHours(
          this.minReservationLength
        )
      }
      this.applyReservationTimeLimit()
      this.minEndTime = moment(this.startTime)
        .add(15, 'minutes')
        .toISOString()
    },
    rawTimeslots(_nextTimeslots, prevTimeslots) {
      if (this.navigated) {
        this.selectedDate = moment(this.navigatedDate).format(
          this.selectedDateFormat
        )
      }
      const now = moment().tz('UTC')
      const useGlobalState =
        (this.mode === 'global' ||
          !this.initialized ||
          this.navigated) &&
        Object.values(this.timepickerGlobalState).every(Boolean)
      this.initialized = true
      const unorderedTimeslots = this.rawTimeslots.map(
        rawTimeslot => ({
          startTime: rawTimeslot.startDateTime,
          endTime: rawTimeslot.endDateTime,
          hint: rawTimeslot.hint
        })
      )
      this.allAvailableTimeslots = _orderBy(unorderedTimeslots, [
        'startTime',
        'endTime',
        'hint'
      ])

      this.timeslots = this.allAvailableTimeslots.filter(timeslot =>
        moment(timeslot.endTime).isSameOrAfter(now)
      )

      if (_isEmpty(this.timeslots)) {
        this.$store.commit(`common/${SET_TIMEPICKER_STATE}`, {
          date: this.formatDate(this.selectedDate),
          startTime: this.bindTimeWithDate(
            this.selectedDate,
            this.startTime
          ),
          endTime: this.bindTimeWithDate(
            this.selectedDate,
            this.endTime
          )
        })

        return
      }

      if (useGlobalState) {
        this.selectedDate = moment(
          this.timepickerGlobalState.date
        ).format(this.selectedDateFormat)
      }

      let disableSlots = true

      if (useGlobalState) {
        if (this.timeslotsEnabled) {
          const matchingTimeslot = this.timeslots.find(
            timeSlot =>
              moment(timeSlot.startTime).isSame(
                this.timepickerGlobalState.startTime
              ) &&
              moment(timeSlot.endTime).isSame(
                this.timepickerGlobalState.endTime
              )
          )
          if (matchingTimeslot) {
            this.selectedTimeslot = matchingTimeslot

            this.startTime = matchingTimeslot.startTime
            this.endTime = matchingTimeslot.endTime
          } else {
            const nearestTimeslot = this.selectNearestTimeslot(
              this.timeslots
            )
            this.selectedTimeslot = nearestTimeslot
            this.startTime = nearestTimeslot?.startTime
            this.endTime = nearestTimeslot?.endTime
          }

          this.$store.commit(`common/${SET_ALERT}`, {
            seconds: 3,
            text: this.$t('timeslotAutoSelected'),
            variant: 'success'
          })

          this.$store.commit(`common/${SET_TIMEPICKER_STATE}`, {
            startTime: this.startTime,
            endTime: this.endTime
          })

          return
        }
        this.startTime = this.timepickerGlobalState.startTime
        this.endTime = this.timepickerGlobalState.endTime
        disableSlots = false

        this.disableUnavailableTimeSlots()
        if (this.autoUpdateEndTime) {
          this.restrictEndTimeInsideOpeningHours(
            this.minReservationLength
          )
        }

        this.applyReservationTimeLimit()

        this.$store.commit(`common/${SET_TIMEPICKER_STATE}`, {
          startTime: this.startTime,
          endTime: this.endTime,
          date: this.formatDate(this.selectedDate)
        })
      }

      if (disableSlots) {
        this.disableUnavailableTimeSlots()
      }

      if (!useGlobalState && this.timeslots.length > 0) {
        this.setDefaultTimes()
      }
    }
  },
  mounted() {
    this.applyReservationTimeLimit()
    this.addKeyboardListeners()
    this.setMaxDate()
  },
  methods: {
    formatDate(date) {
      return moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS')
    },
    onModalToggle(val) {
      this.isOpen = val
    },
    clearApplicationState() {
      localStorage.removeItem('keySteps')
      localStorage.removeItem('endTimeKeySteps')
      localStorage.removeItem('initiated')
    },
    addKeyboardListeners() {
      // clear state
      this.clearApplicationState()

      // add global listener
      window.addEventListener('keyup', ({ keyCode }) => {
        // key down press functionality
        if (
          keyCode === 40 &&
          this.$refs.startTimeRef &&
          this.$refs.startTimeRef.isOpen
        ) {
          const parent = document.querySelector(
            '.vdatetime-time-picker__list--hours'
          )
          const child = parent.querySelector(
            '.vdatetime-time-picker__item--selected'
          )
          let keySteps = JSON.parse(localStorage.getItem('keySteps'))

          let innerValue = 0
          if (
            !JSON.parse(localStorage.getItem('initiated')) &&
            !keySteps
          ) {
            innerValue = parseInt(10, child?.innerText)
            localStorage.setItem(
              'keySteps',
              JSON.stringify(innerValue)
            )
            localStorage.setItem('initiated', JSON.stringify(true))
          }
          keySteps = JSON.parse(localStorage.getItem('keySteps'))

          keySteps += 1
          if (keySteps <= 24) {
            parent.scrollTop = SCROLLER.RANGE * keySteps
            localStorage.setItem('keySteps', JSON.stringify(keySteps))
          }
        }

        // Key up functionality
        if (
          keyCode === 38 &&
          this.$refs.startTimeRef &&
          this.$refs.startTimeRef.isOpen
        ) {
          const parent = document.querySelector(
            '.vdatetime-time-picker__list--hours'
          )
          const child = parent.querySelector(
            '.vdatetime-time-picker__item--selected'
          )
          let keySteps = JSON.parse(localStorage.getItem('keySteps'))

          if (keySteps !== 0) {
            let innerValue = 0
            if (!keySteps) {
              innerValue = parseInt(10, child?.innerText)
              localStorage.setItem(
                'keySteps',
                JSON.stringify(innerValue)
              )
              localStorage.setItem('initiated', JSON.stringify(true))
            }

            keySteps = JSON.parse(localStorage.getItem('keySteps'))
            keySteps -= 1

            if (keySteps >= 0) {
              parent.scrollTop = SCROLLER.RANGE * keySteps
              localStorage.setItem(
                'keySteps',
                JSON.stringify(keySteps)
              )
            }
          }
        }

        // key down functionality for end time
        if (
          keyCode === 40 &&
          this.$refs.endTimeRef &&
          this.$refs.endTimeRef.isOpen
        ) {
          const parent = document.querySelector(
            '.vdatetime-time-picker__list--hours'
          )
          const child = parent.querySelector(
            '.vdatetime-time-picker__item--selected'
          )
          let endTimeKeySteps = JSON.parse(
            localStorage.getItem('endTimeKeySteps')
          )

          let innerValue = 0
          if (
            !JSON.parse(localStorage.getItem('initiated')) &&
            !endTimeKeySteps
          ) {
            innerValue = parseInt(10, child?.innerText)
            localStorage.setItem(
              'endTimeKeySteps',
              JSON.stringify(innerValue)
            )
            localStorage.setItem('initiated', JSON.stringify(true))
          }
          endTimeKeySteps = JSON.parse(
            localStorage.getItem('endTimeKeySteps')
          )

          endTimeKeySteps += 1
          if (endTimeKeySteps <= 24) {
            parent.scrollTop = SCROLLER.RANGE * endTimeKeySteps
            localStorage.setItem(
              'endTimeKeySteps',
              JSON.stringify(endTimeKeySteps)
            )
          }
        }

        // Key up functionality for end time
        if (
          keyCode === 38 &&
          this.$refs.endTimeRef &&
          this.$refs.endTimeRef.isOpen
        ) {
          const parent = document.querySelector(
            '.vdatetime-time-picker__list--hours'
          )
          const child = parent.querySelector(
            '.vdatetime-time-picker__item--selected'
          )
          let endTimeKeySteps = JSON.parse(
            localStorage.getItem('endTimeKeySteps')
          )

          if (endTimeKeySteps !== 0) {
            let innerValue = 0
            if (!endTimeKeySteps) {
              innerValue = parseInt(10, child?.innerText)
              localStorage.setItem(
                'endTimeKeySteps',
                JSON.stringify(innerValue)
              )
              localStorage.setItem('initiated', JSON.stringify(true))
            }

            endTimeKeySteps = JSON.parse(
              localStorage.getItem('endTimeKeySteps')
            )
            endTimeKeySteps -= 1

            if (endTimeKeySteps >= 0) {
              parent.scrollTop = SCROLLER.RANGE * endTimeKeySteps
              localStorage.setItem(
                'endTimeKeySteps',
                JSON.stringify(endTimeKeySteps)
              )
            }
          }
        }
      })
    },
    getTimeObjects(date) {
      return moment(
        moment(date)
          .tz(this.buildingTimezone)
          .format('HH:mm'),
        'HH:mm'
      )
    },
    bindTimeWithDate(date, time) {
      return moment(
        `${moment(date).format('YYYY-MM-DD')} ${moment(time).format(
          'HH:mm:ss'
        )}`,
        'YYYY-MM-DD HH:mm:ss'
      ).toISOString()
    },
    selectNearestTimeslot(timeslots) {
      const now = this.getTimeObjects(moment())
      const validTimeslots = timeslots.filter(timeslot =>
        this.getTimeObjects(timeslot.endTime).isSameOrAfter(now)
      )
      if (validTimeslots.length) {
        return validTimeslots[0]
      }
      return timeslots[0]
    },
    restrictEndTimeInsideOpeningHours(minReservationTime) {
      let isEndTimeUpdated = false
      for (let index = 0; index < this.timeslots.length; index += 1) {
        const { startTime, endTime } = this.timeslots[index]
        let newEndTime = ''
        if (
          moment(this.startTime).isSame(startTime) ||
          moment(this.startTime).isBetween(startTime, endTime)
        ) {
          newEndTime = endTime
          if (minReservationTime) {
            newEndTime = moment(this.startTime)
              .add(minReservationTime, 'minutes')
              .toISOString()
            if (moment(newEndTime).isAfter(endTime)) {
              newEndTime = endTime
            }
          }
          this.endTime = newEndTime
          isEndTimeUpdated = true
          break
        }
      }
      if (!isEndTimeUpdated) {
        this.endTime = moment(this.startTime)
          .add(minReservationTime || 30, 'minutes')
          .toISOString()
      }
    },
    applyReservationTimeLimit() {
      if (
        this.timeslots.length &&
        !this.timeslotsEnabled &&
        this.reservationSettings.reservationTimeLimit
      ) {
        console.log(
          `Restricting reservations to ${this.reservationSettings.reservationTimeLimit} minutes`
        )
        const timeLimit = Number.parseInt(
          this.reservationSettings.reservationTimeLimit,
          10
        )

        if (this.autoUpdateEndTime) {
          this.restrictEndTimeInsideOpeningHours(
            this.minReservationLength
          )
        }

        const maxEndTimeAccordingToLimit = moment(this.startTime)
          .add(timeLimit, 'minutes')
          .toISOString()

        this.maxEndTime = maxEndTimeAccordingToLimit

        this.$store.commit(`common/${SET_TIMEPICKER_STATE}`, {
          startTime: this.startTime,
          endTime: this.endTime,
          date: this.formatDate(this.selectedDate)
        })
      }
    },
    createTimeslotLabel({ startTime, endTime, hint }) {
      return `${moment(startTime)
        .tz(this.buildingTimezone)
        .format('HH:mm')} - ${moment(endTime)
        .tz(this.buildingTimezone)
        .format('HH:mm')}${hint ? ` ${hint}` : ''}`
    },
    applyChanges() {
      this.$bvModal.hide('modal-changedate')
      this.$store.commit(`common/${SET_TIMEPICKER_STATE}`, {
        startTime: this.startTime,
        endTime: this.endTime,
        date: this.formatDate(this.selectedDate)
      })
    },
    async setDefaultTimes() {
      if (this.timeslotsEnabled) {
        const [selectedTimeSlot] = this.timeslots
        this.selectedTimeslot = selectedTimeSlot

        this.$store.commit(`common/${SET_TIMEPICKER_STATE}`, {
          startTime: this.selectedTimeslot.startTime,
          endTime: this.selectedTimeslot.endTime,
          date: this.formatDate(this.selectedDate)
        })
      } else {
        this.startTime = this.minStartTime
        this.restrictEndTimeInsideOpeningHours(
          this.minReservationLength
        )

        this.applyReservationTimeLimit()

        this.$store.commit(`common/${SET_TIMEPICKER_STATE}`, {
          startTime: this.startTime,
          endTime: this.endTime,
          date: this.formatDate(this.selectedDate)
        })
        this.fixMinStartTime = this.minStartTime
        this.fixMaxEndTime = this.maxEndTime
      }
    },
    setMaxDate() {
      this.maxReservations = Number(
        this.reservationSettings?.futureDayMaximum
      )

      const [maxReservations] =
        this.keycloakUserProfile?.attributes
          ?.futureReservationMaximum || []
      if (
        maxReservations &&
        maxReservations >= this.maxReservations
      ) {
        this.maxReservations = Number(maxReservations)
      }
      if (this.maxReservations > 0) {
        this.maxDate = moment(this.minDate)
          .add(this.maxReservations, 'days')
          .endOf('day')
          .toISOString()
      }
    },
    disableUnavailableTimeSlots() {
      const { minStartTime, maxEndTime } = this.timeslots.reduce(
        (result, timeslot) => {
          const { startTime, endTime } = timeslot

          if (_isEmpty(result)) {
            result.minStartTime = startTime
            result.maxEndTime = endTime
          } else {
            if (
              moment(result.minStartTime).isAfter(moment(startTime))
            ) {
              result.minStartTime = startTime
            }
            if (moment(result.maxEndTime).isBefore(moment(endTime))) {
              result.maxEndTime = endTime
            }
          }

          return result
        },
        {}
      )
      const minutes = moment().minutes()
      const nextMinutes =
        minutes - (minutes % 15) + (minutes % 15 ? 15 : 0)
      const minNow = moment()
        .tz('UTC')
        .set({
          minute: nextMinutes,
          seconds: 0,
          milliseconds: 0
        })
      if (minNow.isAfter(minStartTime)) {
        this.minStartTime = minNow.toISOString()
      } else {
        this.minStartTime = minStartTime
      }
      this.maxStartTime = moment(maxEndTime)
        .subtract(15, 'minutes')
        .toISOString()
      this.minEndTime = moment(this.minStartTime)
        .add(15, 'minutes')
        .toISOString()
      if (moment(this.minEndTime).isAfter(maxEndTime)) {
        this.minEndTime = maxEndTime
      }

      this.maxEndTime = maxEndTime
      if (minNow.isSameOrAfter(this.maxEndTime)) {
        this.timeslots = []
      }
    },

    disabledBeforeTodayAndAfterMaxDate(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)

      return (
        date < today ||
        (this.maxReservations > 0 &&
          date >
            new Date(
              today.getTime() +
                this.maxReservations * 24 * 3600 * 1000
            ))
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.form-group::v-deep {
  .vdatetime-input {
    border: none;
    width: 100%;
    background-color: transparent;
    padding: 0.25rem 0.5rem;
  }
}
</style>

<style scss>
.popup_date-picker {
  .mx-table-date .cell {
    &.active {
      background-color: #5440a5;
    }
    &.today {
      color: #5440a5;
      &.active {
        color: white;
      }
    }
  }

  .mx-calendar {
    width: 300px;
    padding: 20px 10px;

    th,
    td,
    button {
      font-size: 16px;
    }

    @media all and (max-width: 500px) {
      width: 100%;
    }
  }
}
</style>
