<template>
  <div
    v-if="activeGroup.orgName"
    class="row col-lg d-xl-none mobile-bottom p-3 justify-content-md-center"
  >
    <div
      v-if="activeGroup"
      class="current-org col-md-auto text-center"
    >
      <b-nav-item
        v-if="reservationsEnabled"
        class="col-md-auto"
        :to="{ name: 'WorkplaceReservationPage' }"
      >
        <img src="@/assets/img/building.svg" alt="" />
        {{ activeGroup.orgName }}
      </b-nav-item>
      <b-nav-item
        v-if="!reservationsEnabled && sensorsEnabled"
        class="col-md-auto"
        :to="{ name: 'SensorsDataPage' }"
      >
        <img src="@/assets/img/building.svg" alt="" />
        {{ activeGroup.orgName }}
      </b-nav-item>
    </div>
    <div class="col-md-auto text-center">
      <router-link
        v-if="pageName != 'BuildingsPage'"
        class="btn btn-secondary btn-mobile"
        :title="$t('changeBuilding')"
        :to="{ name: 'BuildingsPage' }"
      >
        {{ $t('change') }}
      </router-link>
    </div>
  </div>
</template>

<script>
import i18n from '@/plugins/i18n'
import { mapGetters, mapState } from 'vuex'
import moment from 'moment'
import axios from 'axios'

export default {
  name: 'MainNav',
  data() {
    return {
      languages: [
        { flag: 'us', language: 'en', title: 'English' },
        { flag: 'nl', language: 'nl', title: 'Dutch' },
        { flag: 'es', language: 'es', title: 'Spanish' },
        { flag: 'fr', language: 'fr', title: 'French' },
        { flag: 'de', language: 'de', title: 'German' }
      ],
      currentLang: 'English'
    }
  },
  computed: {
    pageName() {
      return this.$route.name
    },
    ...mapState('common', {
      organisation: '$organisation',
      organisationUrls: 'organisationUrls',
      userInfo: '$userInfo',
      isKiosk: 'isKiosk',
      isDashboardAdmin: '$isDashboardAdmin'
    }),
    ...mapGetters('common', [
      'findMyColleaguesEnabled',
      'hasOrganisationAccess',
      'reservationsEnabled',
      'sensorsEnabled',
      'insightsEnabled',
      'isAdmin',
      'isAdminOrCoordinator',
      'activeGroup'
    ])
  },
  mounted() {
    const userLang = navigator.language
    if (this.$cookie.get('lang') && this.$cookie.get('lang').length) {
      for (let i = 0; i < this.languages.length; i += 1) {
        if (this.languages[i].language === this.$cookie.get('lang')) {
          this.changeLocale(
            this.$cookie.get('lang'),
            this.languages[i].title
          )
        }
      }
    } else if (userLang) {
      for (let i = 0; i < this.languages.length; i += 1) {
        if (this.languages[i].language === userLang) {
          this.changeLocale(
            this.languages[i].language,
            this.languages[i].title
          )
        }
      }
    }
    axios.interceptors.request.use(
      config => {
        config.headers['Accept-Language'] = i18n.locale
        return config
      },
      error => Promise.reject(error)
    )
  },
  methods: {
    changeLocale(locale, title) {
      i18n.locale = locale
      moment.locale(this.$i18n.locale)
      this.$cookie.set('lang', i18n.locale, 30)
      this.currentLang = title
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/globals/vars.scss';
@import '@/assets/scss/globals/mixins.scss';
@import '@/assets/scss/globals/extend.scss';

.mobile-bottom {
  display: flex;
  align-items: center;

  @include r(1200) {
    width: 100%;
    display: block;
  }
  .nav-item {
    list-style: none;
  }
}

.current-org {
  display: none;
  font-size: 14px;
  font-weight: 500;
  align-items: center;
  margin-right: 1rem;
  img {
    width: 24px;
    margin-right: 6px;
    margin-top: -1px;
  }
  @include r(1200) {
    display: flex;
  }
}

.mobile-bottom {
  margin-left: auto;
  @include r(1200) {
    justify-content: center;
  }
}
</style>
