import OkkuApi from '@/services/OkkuApi'
import * as mutations from './mutationTypes'

const actions = {
  async loadSettings({ commit }, payload) {
    const settings = await OkkuApi.getSettingsForKiosk(payload)

    commit(mutations.SET_SETTINGS, settings)
  },

  async loadFloors({ commit }, payload) {
    const floors = await OkkuApi.getFloorsForKiosk(payload)

    commit(mutations.SET_FLOORS, floors)
  },

  async loadRooms({ commit }, payload) {
    const rooms = await OkkuApi.getRoomsForKiosk(payload)

    commit(mutations.SET_ROOMS, rooms)
  },

  async loadDesks({ commit }, payload) {
    const desks = await OkkuApi.getDesksForKiosk(payload)

    commit(mutations.SET_DESKS, desks)
  },

  async loadKiosk({ commit, dispatch, state }, orgPath) {
    if (orgPath) {
      dispatch('loadSettings', orgPath)
      dispatch('loadFloors', orgPath)
      dispatch('loadRooms', orgPath)
      dispatch('loadDesks', orgPath)
    } else {
      commit(mutations.SET_EMPTY_ASSETS)
    }
  }
}

export default actions
