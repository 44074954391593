import OkkuApi from '@/services/OkkuApi'
import * as mutations from './mutationTypes'

const actions = {
  async loadReservations({ commit }, payload) {
    const {
      fromDate,
      email,
      pageOffset = undefined,
      pageSize = undefined,
      searchQuery = '',
      orderBy = 'start_datetime',
      direction = 'ASC',
      toDate = null,
      filters = {}
    } = payload

    const reservations = await OkkuApi.getReservations(
      fromDate,
      email,
      pageOffset,
      pageSize,
      searchQuery,
      orderBy,
      direction,
      toDate,
      filters
    )

    commit(mutations.SET_RESERVATIONS, reservations)
  },

  resetModuleState({ commit }) {
    commit(mutations.RESET_MODULE_STATE)
  }
}

export default actions
