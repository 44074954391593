export const getDefaultState = () => ({
  $settings: {},
  $lastUpdate: Date.now(),
  organisationReservationSettings: {},
  dashboardSettings: {},
  organisationUrls: {
    infoUrl: '',
    logoUrl: ''
  },
  organisationModules: {
    sensor: true,
    workplace: false,
    insights: true,
    loading: true
  },
  desks: [],
  floors: [],
  rooms: []
})

const state = getDefaultState()

export default state
