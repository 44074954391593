<template>
  <div>
    <div v-if="loading">
      <div class="loader">
        <b-spinner label="Spinning" />
      </div>
    </div>
    <div v-else>
      <form class="tabe-responsive" @submit.prevent="processForm">
        <table
          ref="table"
          class="table-style table-settings table_sort"
        >
          <thead>
            <tr>
              <th>#</th>
              <th>{{ $t('employee') }}</th>
              <th>{{ $t('period') }}</th>
              <th>
                {{ $t('repeatOn') }}
                <span
                  v-b-tooltip.hover
                  class="tip-icon"
                  :title="$t('repeatOnHelp')"
                >
                  <font-awesome-icon icon="question-circle" />
                </span>
              </th>
              <th>
                <span
                  v-b-tooltip.hover
                  class="tip-icon"
                  :title="$t('howManageAbsences')"
                >
                  <font-awesome-icon icon="question-circle" />
                </span>
                <span
                  class="btn btn-secondary"
                  @click="addAbsenceRow()"
                >
                  + {{ $t('add') }}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(period, index) in absences">
              <tr
                :key="index"
                :data-val="period.regularUser"
                :data-startdate="period.startDatetime"
                :data-enddate="period.endDatetime"
              >
                <td>{{ index + 1 }}</td>
                <td :data-label="$t('employee') + ':'">
                  <strong>
                    {{ displayName }}
                  </strong>
                </td>
                <td :data-label="$t('date') + ':'">
                  <div class="dates-row">
                    <span class="sep">{{ $t('startsOn') }}:</span>
                    <datetime
                      v-model="period.startDatetime"
                      :input-id="'startDate-' + index"
                      class="inp-dates inp-date"
                      format="DD"
                      :type="period.allDay ? 'date' : 'datetime'"
                      @close="startDatetimeChanged(period)"
                    />
                    <datetime
                      v-model="period.startDatetime"
                      :input-id="'startTime-' + index"
                      class="inp-dates inp-time"
                      :class="{
                        disabled: period.allDay
                      }"
                      type="time"
                      :minute-step="15"
                      @close="startDatetimeChanged(period)"
                    />
                    <span class="sep">{{ $t('endsOn') }}:</span>
                    <datetime
                      v-model="period.endDatetime"
                      :input-id="'endDate-' + index"
                      class="inp-dates inp-date"
                      format="DD"
                      :type="period.allDay ? 'date' : 'datetime'"
                      :min-datetime="period.startDatetime"
                      :minute-step="15"
                      @close="endDatetimeChanged(period)"
                    />
                    <datetime
                      v-model="period.endDatetime"
                      :input-id="'endTime-' + index"
                      class="inp-dates inp-time"
                      :class="{
                        disabled: period.allDay
                      }"
                      type="time"
                      :minute-step="15"
                      @close="endDatetimeChanged(period)"
                    />
                    <label class="check-wrap">
                      <input
                        v-model="period.allDay"
                        type="checkbox"
                        @change="checkAllDay(period)"
                      />
                      <span class="lbl">{{ $t('allDay') }}</span>
                    </label>
                  </div>
                </td>
                <td :data-label="$t('repeatOn') + ':'">
                  <div class="days-check">
                    <label class="days-check--item">
                      <input
                        v-model="period.repeatsMonday"
                        type="checkbox"
                      />
                      <span class="lbl">M</span>
                    </label>
                    <label class="days-check--item">
                      <input
                        v-model="period.repeatsTuesday"
                        type="checkbox"
                      />
                      <span class="lbl">T</span>
                    </label>
                    <label class="days-check--item">
                      <input
                        v-model="period.repeatsWednesday"
                        type="checkbox"
                      />
                      <span class="lbl">W</span>
                    </label>
                    <label class="days-check--item">
                      <input
                        v-model="period.repeatsThursday"
                        type="checkbox"
                      />
                      <span class="lbl">T</span>
                    </label>
                    <label class="days-check--item">
                      <input
                        v-model="period.repeatsFriday"
                        type="checkbox"
                      />
                      <span class="lbl">F</span>
                    </label>
                    <label class="days-check--item">
                      <input
                        v-model="period.repeatsSaturday"
                        type="checkbox"
                      />
                      <span class="lbl">S</span>
                    </label>
                    <label class="days-check--item">
                      <input
                        v-model="period.repeatsSunday"
                        type="checkbox"
                      />
                      <span class="lbl">S</span>
                    </label>
                  </div>
                </td>
                <td data-label>
                  <span
                    class="remove_parent link-remove"
                    @click="
                      ;(currentIndex = index),
                        $bvModal.show('modal-remove')
                    "
                  >
                    <font-awesome-icon icon="times" />
                    {{ $t('remove') }}
                  </span>
                </td>
              </tr>
            </template>
            <div v-if="!absences.length">
              <tr>
                <td>
                  <strong>{{ $t('noRecordsFound') }}</strong>
                </td>
              </tr>
            </div>
          </tbody>
        </table>
        <div v-if="absences.length" class="editdesk-container--foot">
          <button type="submit" class="btn btn-primary btn-lg">
            {{ $t('save') }}
          </button>
        </div>
      </form>
      <b-modal
        id="modal-remove"
        :on-enter-key-press="() => deleteAbsence(currentIndex)"
        hide-footer
      >
        <template v-slot:modal-title>
          <strong>{{ $t('remove') }}</strong>
        </template>
        <div class="d-block text-center">
          <p>{{ $t('deleteConfirmation') }}</p>
          <b-button
            variant="danger"
            class="mt-1"
            @click="$bvModal.hide('modal-remove')"
          >
            {{ $t('no') }}
          </b-button>
          <b-button
            class="mt-1"
            primary
            @click="deleteAbsence(currentIndex)"
          >
            {{ $t('yes') }}
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import moment from 'moment-timezone'
import { mapState } from 'vuex'
import { Datetime } from 'vue-datetime'

import OkkuApi from '@/services/OkkuApi'

import BootstrapModalHOC from '@/components/hocs/BootstrapModal'

import 'vue-datetime/dist/vue-datetime.css'

export default {
  name: 'AbsencesTable',
  components: {
    datetime: Datetime,
    'b-modal': BootstrapModalHOC
  },

  props: {
    email: {
      type: String,
      required: true
    },
    displayName: {
      type: String,
      default: ''
    },
    isNeedToRefresh: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      absences: [],
      currentIndex: null,
      loading: true
    }
  },
  computed: {
    ...mapState('common', {
      organisation: '$organisation',
      settings: 'organisationReservationSettings'
    })
  },
  watch: {
    organisation() {
      this.getAbsences()
    },
    isNeedToRefresh(newVal) {
      if (newVal) {
        this.getAbsences()
      }
    }
  },

  mounted() {
    if (this.organisation) {
      this.getAbsences()
    }
  },

  methods: {
    updateAlert(variant, text) {
      this.$emit('updateAlert', { variant, text })
    },

    async getAbsences() {
      this.loading = true
      const absences = await OkkuApi.getAbsences(
        this.email
      ).catch(() => [])
      this.absences = absences
      this.loading = false
    },

    checkAllDay(period) {
      if (!period.allDay) {
        return
      }
      period.startDatetime = this.setToBeginningOfDay(
        period.startDatetime
      )

      period.endDatetime = this.setToEndOfDay(period.endDatetime)
    },

    setToBeginningOfDay(dateTime) {
      return moment(dateTime)
        .startOf('day')
        .toISOString()
    },

    setToEndOfDay(dateTime) {
      return moment(dateTime)
        .endOf('day')
        .toISOString()
    },

    startDatetimeChanged(period) {
      const endDate = period.endDatetime
        ? moment(period.endDatetime)
        : null

      if (endDate?.isBefore(moment(period.startDatetime))) {
        const startDate = moment(period.startDatetime)
        const newEndDate = moment(startDate)
          .add(1, 'day')
          .hours(endDate.hours())
          .minutes(endDate.minutes())
          .seconds(endDate.seconds())
          .milliseconds(0)

        period.endDatetime = newEndDate.toISOString()
      }
    },

    endDatetimeChanged(period) {
      if (!period.endDatetime) {
        return
      }
      if (period.allDay) {
        period.endDatetime = this.setToEndOfDay(period.endDatetime)
      }
      const endDate = moment(period.endDatetime)
      const startDate = moment(period.startDatetime)
      if (endDate.isBefore(startDate)) {
        period.endDatetime = startDate
          .add(1, 'day')
          .set({
            hour: endDate.hour(),
            minute: endDate.minute(),
            second: endDate.second(),
            millisecond: 0
          })
          .toISOString()
      }
    },

    async processForm() {
      try {
        // Check if start and end date times are selected
        if (!this.validateDateTimesSelected(this.absences)) return

        this.$emit('updateAbsences', this.absences)
      } catch (error) {
        console.error(error)
        this.updateAlert(
          'danger',
          error.response?.data ||
            error.message ||
            'An error occurred.'
        )
      }
    },

    validateDateTimesSelected(absences) {
      for (let i = 0; i < absences.length; i += 1) {
        const absence = absences[i]
        if (!absence.createdDatetime) {
          this.updateAlert(
            'danger',
            `Please select start date time on row ${i + 1}`
          )
          return false
        }
        if (!absence.endDatetime) {
          this.updateAlert(
            'danger',
            `Please select end date time on row ${i + 1}`
          )
          return false
        }
      }
      return true
    },

    async deleteAbsence(index) {
      try {
        const plannedAbsence = this.absences[index]
        const { regularUser, createdDatetime } = plannedAbsence
        await OkkuApi.deleteAbsence(regularUser, createdDatetime)
        this.absences.splice(index, 1)
        await this.getAbsences()
        this.updateAlert('success', this.$t('changesSaved'))
      } catch (error) {
        this.updateAlert('danger', error.message)
      } finally {
        this.$bvModal.hide('modal-remove')
      }
    },

    addAbsenceRow() {
      const {
        startOfWorkingDay = 8,
        endOfWorkingDay = 18
      } = this.settings

      const startDate = moment()
        .hours(startOfWorkingDay)
        .startOf('hour')
      if (moment().hours() >= endOfWorkingDay) {
        startDate.add(1, 'days')
      }

      const absenceObject = {
        createdDatetime: new Date().toISOString(),
        startDatetime: startDate.toISOString(),
        endDatetime: moment(startDate)
          .hours(endOfWorkingDay)
          .toISOString(),
        allDay: false,
        repeatsMonday: false,
        repeatsTuesday: false,
        repeatsWednesday: false,
        repeatsThursday: false,
        repeatsFriday: false,
        repeatsSaturday: false,
        repeatsSunday: false,
        regularUser: this.email
      }
      this.absences.push(absenceObject)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/globals/vars.scss';
@import '@/assets/scss/globals/extend.scss';
.tip-icon::v-deep {
  .fa-question-circle {
    font-size: 16px;
    margin-right: 5px;
    cursor: pointer;
  }
}

table {
  margin-top: 20px;
  input {
    display: inline-block;
    vertical-align: middle;
  }
}

.dates-row::v-deep {
  font-size: 0;
  width: 100%;
  margin-top: 5px;
  min-width: 100px;
  .vdatetime-calendar__current--month {
    font-size: 14px;
  }
  .check-wrap {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 5px;
    margin-left: 10px;
    &:first-child {
      margin-left: 0;
    }
  }
  .sep {
    display: inline-block;
    vertical-align: middle;
    //width: 100%;
    font-size: 12px;
    margin-right: 5px;
    margin-bottom: 5px;
    &.disabled {
      opacity: 0.4;
      pointer-events: none;
    }
  }
  .inp-dates {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    margin-bottom: 5px;
    input {
      background-color: rgba($bordercolor, 0.5);
      height: 30px;
      font-size: 12px;
      width: 92px;
      border: none;
      text-align: center;
      padding: 0 10px;
    }

    &:last-child {
      margin-right: 0;
    }
    &.disabled {
      opacity: 0.4;
      pointer-events: none;
    }
    &.inp-time {
      &.disabled {
        visibility: hidden;
      }
      input {
        width: 52px;
      }
    }
  }
  .loader {
    justify-content: center;
    margin-top: 15%;
    text-align: center;
  }
}
</style>
