import OkkuApi from '@/services/OkkuApi'
import { ACTIONS, PublicScreenTypesEnum } from '@/constants'
import * as mutations from './mutationTypes'

const actions = {
  async deletePublicScreenToken({ commit }, payload) {
    const { token, type } = payload
    if (type === PublicScreenTypesEnum.DAILY_RESERVATION) {
      const {
        data: { status, message }
      } = await OkkuApi.deleteDailyReservationToken(token)

      return { status, message }
    }
    if (type === PublicScreenTypesEnum.PUBLIC_SCREEN) {
      const {
        data: { status, message }
      } = await OkkuApi.deletePublicScreenToken(token)

      return { status, message }
    }
    return false
  },

  async loadPublicScreenTokens({ commit }) {
    const { data } = await OkkuApi.getPublicScreenTokens()
    commit(mutations.SET_PUBLIC_SCREENS_TOKENS, data)
  },
  async upsertPublicScreenTokenStatus({ commit }, payload) {
    const { token, action } = payload

    const { tokenId } = token
    const tokenStatus = action === ACTIONS.APPROVED
    const {
      data: { status, message }
    } = await OkkuApi.upsertTokenStatus(tokenId, tokenStatus, action)
    return { status, message }
  },

  async setDailyReservationsThemeSettings({ commit }, payload) {
    const { data } = await OkkuApi.getBuildingThemeSettings(
      payload.org,
      payload.orgPath
    )
    const [settings] = data

    commit(mutations.SET_DAILY_RESERVATIONS_THEME_SETTINGS, settings)
  },

  resetModuleState({ commit }) {
    commit(mutations.RESET_MODULE_STATE)
  }
}

export default actions
