import Vue from 'vue'
import VueI18n from 'vue-i18n'

import nl from './messages/nl.json'
import en from './messages/en.json'
import es from './messages/es.json'
import fr from './messages/fr.json'
import de from './messages/de.json'

Vue.use(VueI18n)

const messages = {
  en,
  nl,
  es,
  fr,
  de
}

const i18n = new VueI18n({
  locale: 'en', // set locale
  fallbackLocale: 'nl', // set fallback locale
  messages
})
export default i18n
