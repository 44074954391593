<template>
  <div class="container">
    <b-modal id="modal-account" hide-footer size="lg">
      <template v-slot:modal-title>
        <div class="row">
          <div class="col-md-2">
            <font-awesome-icon icon="user-circle" />
          </div>
          <div class="col-md-9">
            {{ $t('account') }}
          </div>
        </div>
      </template>
      <b-card class="mb-4">
        <template #header>
          <h6 class="mb-0 float-left">
            {{ userinfo.userDisplayName }}
          </h6>
        </template>
        <b-card-text>
          <b-row>
            <div class="column col-lg-10 col-8">
              {{ keycloakUserProfile.email }}
              <span v-if="keycloakUserProfile.attributes.organisation">
                {{ $t('userOrganisation') }}
                <span v-for="value in keycloakUserProfile.attributes
                  .organisation" :key="value">
                  {{ value }}
                </span>
              </span>
              <span v-if="keycloakUserProfile.attributes.cardId">
                {{ $t('cardId') }}
                <span v-for="value in keycloakUserProfile.attributes
                  .cardId" :key="value">
                  {{ value }}
                </span>
              </span>
              <b-badge v-if="keycloakUserProfile.attributes.isEmergencyResponder
                " variant="success">
                {{ $t('isEmergencyResponder') }}
              </b-badge>
            </div>
            <div class="column col-lg-2 col-4">
              <b-button variant="outline-secondary" size="sm" class="float-right" @click="logout">
                <font-awesome-icon icon="sign-out-alt" />
                {{ $t('logout') }}
              </b-button>
            </div>
          </b-row>
        </b-card-text>
      </b-card>
      <b-card class="mb-4" no-body>
        <template #header>
          <h6 v-b-toggle.rewards class="mb-0">
            {{ $t('rewards') }}
            <template v-if="!showRewardsButton">
              <RewardsPageGuiding />
            </template>
          </h6>
          <font-awesome-icon :icon="'caret-down'" class="collapse-down-icon" />
        </template>
        <b-collapse id="rewards">
          <b-card-body>
            <b-card-text>
              <div style="display: flex;align-items: center;justify-content: space-between;width: 100%;">
                <span> {{ $t('rewardInviteMessage') }}</span>
                <b-button class="btn btn-outline-secondary" size="sm" @click="updateRewardsPageStatus">
                  {{ $t('rewards') }}
                </b-button>
              </div>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>
      <b-card class="mb-4 pb-0" no-body>
        <template #header>
          <h6 v-b-toggle.authorisations class="mb-0">
            {{ $t('authorisations') }}
          </h6>
          <font-awesome-icon :icon="openAuthorisations ? 'caret-up' : 'caret-down'" class="collapse-down-icon"
            @click="openAuthorisations = !openAuthorisations" />
        </template>
        <b-collapse id="authorisations" v-model="openAuthorisations">
          <b-card-body>
            <b-card-text>
              <span v-if="keycloakUserProfile.attributes.overrideUserpool">
                {{ $t('yourAccountType') }}
                <span v-for="value in keycloakUserProfile.attributes
                      .overrideUserpool" :key="value">
                  <b-badge variant="success" class="mb-1">{{
                    value
                  }}</b-badge>
                </span>
              </span>
              <span v-else-if="keycloakUserProfile.attributes.userpool">
                {{ $t('yourAccountType') }}
                <span v-for="value in keycloakUserProfile.attributes
                  .userpool" :key="value">
                  <b-badge variant="success">{{ value }}</b-badge>
                </span>
              </span>
              <span v-if="Array.isArray(userinfo.roles) &&
                userinfo.roles.length > 0
                ">
                {{ $t('yourRoles') }}
                <span v-for="value in userinfo.roles" :key="value">
                  <b-badge variant="primary" class="mr-1 mb-1">
                    {{ value }}
                  </b-badge>
                </span>
              </span>
              <div v-if="userinfo.roles">
                {{ $t('youHaveAccessToBuildings') }}
                <span v-for="group in userinfo.groups" :key="group.orgName">
                  <b-badge variant="info" class="mr-1">
                    {{ group.orgName }}
                  </b-badge>
                </span>
                <span v-if="Object.keys(userinfo.groups).length === 0">
                  -
                </span>
              </div>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>
      <b-card :v-if="keycloakUserProfile.attributes.futureReservationMaximum ||
        keycloakUserProfile.attributes
          .weeklyReservationHourLimit ||
        keycloakUserProfile.attributes
          .weeklyReservationMinuteLimit
        " class="mb-4" no-body>
        <template #header>
          <h6 v-b-toggle.personalReservationSettings class="mb-0">
            {{ $t('personalReservationSettings') }}
          </h6>
          <font-awesome-icon :icon="openPersonalReserervationSettings
            ? 'caret-up'
            : 'caret-down'
            " class="collapse-down-icon" @click="
    openPersonalReserervationSettings = !openPersonalReserervationSettings
    " />
        </template>
        <b-collapse id="personalReservationSettings" v-model="openPersonalReserervationSettings">
          <b-card-body>
            <b-card-text>
              <div v-if="keycloakUserProfile.attributes
                .futureReservationMaximum
                ">
                {{ $t('youMayReserve') }}
                <span v-for="value in keycloakUserProfile.attributes
                  .futureReservationMaximum" :key="value">
                  {{ value }}
                </span>
                {{ $t('daysAhead') }}
              </div>
              <div v-if="keycloakUserProfile.attributes
                .weeklyReservationHourLimit
                ">
                {{ $t('youMayReserve') }}
                <span v-for="value in keycloakUserProfile.attributes
                  .weeklyReservationHourLimit" :key="value">
                  {{ value }}
                </span>
                {{ $t('hoursPerWeek') }}
              </div>
              <div v-if="keycloakUserProfile.attributes
                .weeklyReservationMinuteLimit
                ">
                {{ $t('youMayReserve') }}
                <span v-for="value in keycloakUserProfile.attributes
                  .weeklyReservationMinuteLimit" :key="value">
                  {{ value }}
                </span>
                {{ $t('minutesPerWeek') }}
              </div>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-link class="float-right mr-4" target="_blank" href="https://www.okku.io/privacy">
        {{ $t('privacyPolicy') }}
      </b-link>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import OkkuApi from '@/services/OkkuApi'
import { mapState } from 'vuex'
import VueClipboard from 'vue-clipboard2'
import RewardsPageGuiding from '@/components/common/RewardsPageGuiding'
import { COMMUNICATION_CONSTANTS } from '@/constants'

VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

export default {
  components: {
    RewardsPageGuiding
  },
  props: {
    userinfo: { required: true, type: Object },
    keycloakUserProfile: { required: true, type: Object }
  },
  data() {
    return {
      openAuthorisations: false,
      openPersonalReserervationSettings: false,
      showRewardsButton: false
    }
  },
  computed: {
    ...mapState('common', {
      userInfo: '$userInfo'
    }),
    rewardsUrl() {
      return {
        name: 'Rewards'
      }
    },
    ...mapState('common', ['isKiosk'])
  },
  async mounted() {
    this.$root.$on(
      COMMUNICATION_CONSTANTS.REWARDS_PAGE_STATUS_INFO,
      data => {
        if (data) {
          this.showRewardsButton = true
        } else {
          this.showRewardsButton = false
        }
      }
    )
  },
  methods: {
    async logout() {
      await OkkuApi.logout()
    },
    async updateRewardsPageStatus() {
      this.$bvModal.hide('modal-account')
      try {
        // Update rewards page status
        await OkkuApi.updateRewardsScreenVisibilityStatus(
          this.userInfo.userId
        )
        // Broadcast message publicly
        this.$root.$emit(
          COMMUNICATION_CONSTANTS.REWARDS_PAGE_INFO_FROM_ACCOUNT_PAGE_TO_MAIN_NAV_BAR,
          true
        )
        this.showRewardsButton = true
        this.$router.push('/workplace/rewards')
      } catch (error) {
        this.$root.$emit(
          'message_from_account_modal_about_rewards_page',
          false
        )
      }
    }
  }
}
</script>

<style lang="scss">
#modal-account {
  .collapse-down-icon {
    position: absolute;
    right: 12px;
    top: 14px;
    font-size: 1rem;
    cursor: pointer;
  }
}
</style>
