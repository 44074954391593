import _pick from 'lodash/pick'

import {
  ALLOW_DESK_RESERVATION_SUBJECT,
  ALLOW_ROOM_RESERVATION_SUBJECT,
  CREATE_AUTHORISATION,
  DELETE_ORG,
  ENABLE_ALL_USERS_TO_INVITE_GUESTS,
  REFRESH,
  RESET_EASYPZ_TRANSFORM_PARAMS,
  REVIEW_PLATFORM,
  SET_ALERT,
  SET_AUTHORISATIONS,
  SET_AZURE_ROOMS,
  SET_DESKS,
  SET_EASY_PZ_TRANSFORM_PARAMS,
  SET_EMPTY_ASSETS,
  SET_FLOORS,
  SET_GOOGLE_ROOMS,
  SET_INFO_URL,
  SET_IS_AUTHENTICATED,
  SET_IS_FORM_DIRTY,
  SET_IS_KIOSK,
  SET_IS_MOBILE,
  SET_KEYCLOAK_USER_PROFILE,
  SET_LOGO_URL,
  SET_READY,
  SET_RESTRICTED_FLOORS,
  SET_ROOMS,
  SET_SENSOR_HISTORY_PARAMS,
  SET_SETTINGS,
  SET_TIMEPICKER_STATE,
  SET_ORGANISATION_NAME,
  SET_USER_INFO,
  TOGGLE_OLD_OPENING_HOURS,
  TOGGLE_PARENT_CHECKBOX,
  UPDATE_AUTHORISATION,
  UPDATE_POP_UP_TEXT_STATUS,
  UPDATE_RESERVATION_SUBJECT_VISIBILITY,
  UPDATE_USER_INFO
} from './mutationTypes'
import {
  initialEasyPzTransformParams,
  localStorageBuilding
} from '../../constants'

const mutations = {
  [UPDATE_POP_UP_TEXT_STATUS]: (state, groups) => {
    state.$userInfo.groups = { ...groups }
  },
  [ALLOW_DESK_RESERVATION_SUBJECT]: (
    state,
    allowDeskReservationSubjects
  ) => {
    if (
      state &&
      state.$userInfo &&
      state.$organisation &&
      state.$userInfo.groups
    ) {
      state.$userInfo.groups[
        state.$organisation
      ].allowDeskReservationSubject = allowDeskReservationSubjects
    }
  },
  [ALLOW_ROOM_RESERVATION_SUBJECT]: (
    state,
    allowRoomReservationSubjects
  ) => {
    if (
      state &&
      state.$userInfo &&
      state.$organisation &&
      state.$userInfo.groups
    ) {
      state.$userInfo.groups[
        state.$organisation
      ].allowRoomReservationSubject = allowRoomReservationSubjects
    }
  },
  [ENABLE_ALL_USERS_TO_INVITE_GUESTS]: (
    state,
    inviteGuestsFlagValue
  ) => {
    if (
      state &&
      state.$userInfo &&
      state.$organisation &&
      state.$userInfo.groups
    ) {
      state.$userInfo.groups[
        state.$organisation
      ].enableAllUsersToInviteGuests = { ...inviteGuestsFlagValue }
    }
  },

  [SET_ORGANISATION_NAME]: (state, orgPath) => {
    state.$organisation = orgPath
    localStorage[localStorageBuilding] = orgPath
  },

  [SET_EMPTY_ASSETS](state) {
    state.floors = []
    state.desks = []
    state.rooms = []
  },

  [REFRESH](state) {
    state.$lastUpdate = Date.now()
  },
  [RESET_EASYPZ_TRANSFORM_PARAMS](state) {
    state.easyPzTransformParams = {
      ...initialEasyPzTransformParams
    }
  },
  [DELETE_ORG](state, org) {
    const groups = { ...state.$userInfo.groups }
    delete groups[`/${org.name}${org.path}`]
    state.$userInfo.groups = groups
  },
  [UPDATE_USER_INFO](state, org) {
    const orgPath = `/${org.name}${org.path}`
    const groups = { ...state.$userInfo.groups }
    groups[orgPath] = {
      allowDeskReservationSubject: false,
      allowPrivateReservations: false,
      allowRoomReservationSubject: false,
      buildingAddress: org.buildingAddress
        ? org.buildingAddress
        : null,
      buildingImageUrl: org.buildingImageUrl
        ? org.buildingImageUrl
        : null,
      buildingMoreInfoUrl: org.buildingMoreInfoUrl
        ? org.buildingMoreInfoUrl
        : null,
      enableAllUsersToInviteGuests: false,
      findMyColleagues: false,
      infoUrl: org.infoUrl ? org.infoUrl : null,
      integrations: org.integrations ? org.integrations : null,
      logoUrl: org.logoUrl ? org.logoUrl : null,
      orgName: org.displayName,
      path: orgPath,
      reservationsEnabled: true,
      timeslotsEnabled: org.timeslot ? org.timeslot : null,
      timezone: org.timezone ? org.timezone : 'Europe/Amsterdam'
    }
    state.$userInfo.groups = groups
  },
  [SET_USER_INFO](state, userInfo) {
    state.$userInfo = userInfo

    if (state.$userInfo.roles) {
      const { roles } = state.$userInfo

      state.$isDashboardAdmin = roles.includes('Dashboard-admin')
      state.$isCustomerAdmin = roles.includes('Customer-admin')
      state.$isTeamLead = roles.includes('Customer-team-lead')
      state.$isSensorViewer = roles.includes('Sensors')
      state.$isCustomerCoordinator = roles.includes(
        'Customer-coordinator'
      )
      state.$isInsightsUser = roles.includes('Insights')
      state.$hasViewCustomAssetRole = roles.includes(
        'View-custom-assets'
      )
    } else {
      state.$isDashboardAdmin = false
      state.$isCustomerAdmin = false
      state.$isCustomerCoordinator = false
      state.$isInsightsUser = false
      state.$isSensorViewer = false
      state.$hasViewCustomAssetRole = false
    }
  },
  [SET_SETTINGS](state, settings) {
    state.$settings = settings
    state.organisationReservationSettings =
      settings.reservationSettings
    state.organisationModules = settings.modules
    state.organisationUrls = _pick(settings, ['logoUrl', 'infoUrl'])
    state.dashboardSettings = {
      ...settings.dashboardSettings,
      hasSensors: settings.hasSensors
    }
  },
  [SET_FLOORS](state, floors) {
    state.floors = floors
  },
  [SET_RESTRICTED_FLOORS](state, floors) {
    state.restrictedFloors = floors
  },
  [SET_ROOMS](state, rooms) {
    state.rooms = rooms
  },
  [SET_AUTHORISATIONS](state, authorisations) {
    state.authorisations = authorisations
  },
  [UPDATE_AUTHORISATION](state, authorisation) {
    state.authorisations = state.authorisations.map(auth =>
      auth.organisationIdentifier ===
      authorisation.organisationIdentifier
        ? authorisation
        : auth
    )
  },
  [CREATE_AUTHORISATION](state, authorisation) {
    const newAuthorisations = state.authorisations
    newAuthorisations.push(authorisation)
    state.authorisations = newAuthorisations
  },
  [SET_DESKS](state, desks) {
    state.desks = desks
  },
  [SET_IS_FORM_DIRTY](state, isDirty) {
    state.dirtyForm = !!isDirty
  },
  [SET_GOOGLE_ROOMS](state, googleRooms) {
    state.googleRooms = googleRooms
  },
  [SET_AZURE_ROOMS](state, azureRooms) {
    state.azureRooms = azureRooms
  },
  [SET_IS_KIOSK](state, isKiosk) {
    state.isKiosk = isKiosk
  },
  [SET_ALERT](state, alert) {
    state.alert = {
      ...alert,
      dismissible: true,
      fade: true
    }
  },
  [SET_IS_AUTHENTICATED](state, isAuthenticated) {
    state.authenticated = isAuthenticated
  },
  [SET_IS_MOBILE](state, isMobile) {
    state.isMobile = isMobile
  },
  [SET_EASY_PZ_TRANSFORM_PARAMS](state, params) {
    state.easyPzTransformParams = params
  },
  [SET_KEYCLOAK_USER_PROFILE](state, profile) {
    state.keycloakUserProfile = profile
  },
  [SET_READY](state, ready) {
    state.ready = ready
  },
  [SET_TIMEPICKER_STATE](state, timepickerState) {
    state.timepicker = {
      ...state.timepicker,
      ...timepickerState
    }
  },
  [SET_LOGO_URL](state, logoUrl) {
    state.organisationUrls.logoUrl = logoUrl
  },
  [SET_INFO_URL](state, infoUrl) {
    state.organisationUrls.infoUrl = infoUrl
  },
  [SET_SENSOR_HISTORY_PARAMS](state, sensorHistoryParams) {
    state.sensorHistoryParams = sensorHistoryParams
  },
  [TOGGLE_OLD_OPENING_HOURS](state, value) {
    state.oldOpeningHours = value
  },
  [TOGGLE_PARENT_CHECKBOX](state, value) {
    state.toggleParentCheckbox = value
  },
  [UPDATE_RESERVATION_SUBJECT_VISIBILITY](
    state,
    reservationSubjectFlagValues
  ) {
    state.$userInfo.groups[
      state.$organisation
    ].allowDeskReservationSubject =
      reservationSubjectFlagValues.deskReservationSubject
    state.$userInfo.groups[
      state.$organisation
    ].allowRoomReservationSubject =
      reservationSubjectFlagValues.roomReservationSubject
  },
  [REVIEW_PLATFORM](state, reviewPlatform) {
    state.reviewPlatform = reviewPlatform
  }
}

export default mutations
