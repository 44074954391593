<template>
  <b-modal
    id="review-modal"
    ref="bmodal"
    :on-enter-key-press="upsert"
    hide-footer
    size="md"
    :title="$t('rate-us')"
    no-close-on-backdrop
    no-close-on-esc
  >
    <p>{{ $t('i-rate-okku-with') }}</p>
    <star-rating v-model="rating" :show-rating="false"></star-rating>
    <br />
    <p>{{ $t('what-can-we-improve') }}</p>
    <textarea v-model="message" class="form-control"></textarea>
    <b-alert
      :show="dismissCountDown"
      variant="danger"
      dismissible
      @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged"
      >{{ $t('please-choose-a-rating') }}</b-alert
    >
    <b-alert
      :show="successCountDown"
      variant="success"
      fade
      dismissible
      @dismissed="successCountDown = 0"
      @dismiss-count-down="successCountDownChanged"
      >{{ $t('rating-saved') }}</b-alert
    >
    <b-overlay :show="loading" rounded="sm"> </b-overlay>
    <b-row align-h="end">
      <b-button
        size="lg"
        class="mt-3 mr-3"
        :disabled="isSaving"
        @click="upsert"
      >
        <b-spinner v-if="isSaving" small></b-spinner>
        {{ isSaving ? $t('savingFeedback') : $t('rate') }}
      </b-button>
    </b-row>
  </b-modal>
</template>
<script>
// import OkkuApi from '@/services/OkkuApi'
import BootstrapModalHOC from '@/components/hocs/BootstrapModal'
import StarRating from 'vue-star-rating'
import OkkuApi from '@/services/OkkuApi'
import { REVIEW_PLATFORM } from '@/store/modules/common/mutationTypes'

export default {
  components: {
    'b-modal': BootstrapModalHOC,
    StarRating
  },
  props: {},
  data() {
    return {
      loading: false,
      // Default rating data
      rating: -1,
      message: '',
      response: '',
      // For Success alert
      successSecs: 3,
      successCountDown: 0,
      // For error alert
      dismissSecs: 5,
      dismissCountDown: 0,
      isSaving: false
    }
  },
  computed: {},
  mounted() {},
  methods: {
    async upsert() {
      if (this.rating !== -1) {
        this.isSaving = true
        const response = await OkkuApi.upsertReview({
          rating: this.rating,
          message: this.message
        })
        if (
          this.rating === 5 &&
          response &&
          response.reviewPlatform &&
          response.reviewUrl
        ) {
          // Commit review platform information to store for post review modal
          this.$store.commit(`common/${REVIEW_PLATFORM}`, response)
          this.$bvModal.show('post-review-modal')
          this.$bvModal.hide('review-modal')
        } else {
          this.showSuccessAlert()
          setTimeout(() => {
            this.$bvModal.hide('review-modal')
          }, 1000)
        }
        this.rating = -1
        this.message = ''
        this.isSaving = false
      } else {
        // No rating has been entered
        this.showAlert()
      }
    },
    // For the alert
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    successCountDownChanged(successCountDown) {
      this.successCountDown = successCountDown
      if (this.successCountDown === 0) {
        this.$bvModal.hide('review-modal')
      }
    },
    showSuccessAlert() {
      this.successCountDown = 3
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    }
  }
}
</script>
