<template>
  <b-navbar-nav v-if="$route.meta.showSubmenu" id="secondary-nav">
    <template v-if="isAdminOrCoordinator">
      <b-nav-item :to="{ name: 'AllReservationsPage' }">
        {{ $t('reservations') }}
      </b-nav-item>
    </template>
    <template v-if="isDashboardAdmin">
      <b-nav-item-dropdown
        :text="$t('building')"
        class="dropdown-user"
        right
        variant="link"
        toggle-class="text-decoration-none"
      >
        <b-nav-item :to="{ name: 'BuildingSettingsPage' }">
          {{ $t('general') }}
        </b-nav-item>
        <b-nav-item :to="{ name: 'AuthorisationsPage' }">
          {{ $t('authorisations') }}
        </b-nav-item>
        <b-nav-item :to="{ name: 'TodayScreens' }">
          {{ $t('TodayScreens') }}
        </b-nav-item>
        <b-nav-item :to="{ name: 'Rewards' }">
          {{ $t('rewards') }}
        </b-nav-item>
      </b-nav-item-dropdown>
    </template>
    <template v-else-if="isCustomerAdmin">
      <b-nav-item-dropdown
        :text="$t('building')"
        class="dropdown-user"
        right
        variant="link"
        toggle-class="text-decoration-none"
      >
        <b-nav-item :to="{ name: 'BuildingSettingsPage' }">
          {{ $t('general') }}
        </b-nav-item>
        <b-nav-item :to="{ name: 'TodayScreens' }">
          {{ $t('TodayScreens') }}
        </b-nav-item>
      </b-nav-item-dropdown>
    </template>
    <template v-else-if="isAdminOrCoordinator">
      <b-nav-item :to="{ name: 'BuildingSettingsPage' }">
        {{ $t('building') }}
      </b-nav-item>
    </template>
    <b-nav-item-dropdown
      v-if="isAdminOrCoordinator"
      :text="$t('assets')"
      class="dropdown-user"
      right
      variant="link"
      toggle-class="text-decoration-none"
    >
      <b-nav-item :to="{ name: 'DesksPage' }">
        {{ $t('desks') }}
      </b-nav-item>
      <template v-if="isAdmin">
        <b-nav-item :to="{ name: 'RoomsPage' }">
          {{ $t('rooms') }}
        </b-nav-item>
        <b-nav-item :to="{ name: 'FloorsPage' }">
          {{ $t('floors') }}
        </b-nav-item>
        <b-nav-item :to="{ name: 'SensorsPage' }">
          {{ $t('sensors') }}
        </b-nav-item>
        <b-nav-item :to="{ name: 'ReservationAssetsPage' }">
          {{ $t('reservationAssets') }}
        </b-nav-item>
      </template>
    </b-nav-item-dropdown>
    <template v-if="isAdmin">
      <b-nav-item :to="{ name: 'TimeslotsPage' }">
        {{ $t('openingHours') }}
      </b-nav-item>
    </template>
    <template v-if="isAdminOrCoordinator">
      <b-nav-item-dropdown
        :text="$t('userManagement')"
        class="dropdown-user"
        right
        variant="link"
        toggle-class="text-decoration-none"
      >
        <b-nav-item v-if="isAdmin" :to="{ name: 'UsersPage' }">
          {{ $t('users') }}
        </b-nav-item>
        <b-nav-item :to="{ name: 'FixedDeskEmployeesPage' }">
          {{ $t('fixedDeskEmployees') }}
        </b-nav-item>
      </b-nav-item-dropdown>
    </template>
    <template v-if="isAdmin">
      <b-nav-item-dropdown
        :text="$t('organisation')"
        class="dropdown-user"
        right
        variant="link"
        toggle-class="text-decoration-none"
      >
        <b-nav-item :to="{ name: 'EmailTemplatesPage' }">
          {{ $t('emailTemplates') }}
        </b-nav-item>
      </b-nav-item-dropdown>
    </template>
  </b-navbar-nav>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'SecondaryNav',
  computed: {
    ...mapGetters('common', [
      'isAdmin',
      'isAdminOrCoordinator',
      'isCustomerAdmin'
    ]),
    ...mapState('common', {
      isDashboardAdmin: '$isDashboardAdmin'
    })
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/globals/vars.scss';
@import '@/assets/scss/globals/mixins.scss';
@import '@/assets/scss/globals/extend.scss';
#secondary-nav::v-deep {
  text-align: center;
  font-size: 0;
  justify-content: center;
  display: block;
  border-bottom: 1px solid $bordercolor;
  background-color: #f8f9ff;
  li.dropdown {
    margin: 0 20px;

    @include r(767) {
      margin: 0;
    }
  }

  .dropdown-menu li {
    display: inline;
    margin: 0;
  }

  li {
    display: inline-block;
    vertical-align: top;
    margin: 0 5px;
    a {
      display: block;
      padding: 20px 20px;
      font-size: 14px;
      text-decoration: none;
      white-space: nowrap;
      color: $textcolor;
      font-weight: 400;
      @include hoverFromCenter($basecolor, -1, 3);
      @include r(500) {
        font-size: 13px;
        padding: 15px 15px;
      }
      @include r(400) {
        font-size: 12px;
        padding: 15px 8px;
      }
      &.active,
      &.router-link-active {
        font-weight: 600;
        &:before {
          opacity: 1;
          width: 100%;
        }
      }
    }
  }
}
</style>
