import 'easypz'
import './assets/scss/index.scss'
import 'vue-swatches/dist/vue-swatches.css'

import {
  faArrowLeft,
  faBuilding,
  faCalendarAlt,
  faCaretDown,
  faCaretUp,
  faChartLine,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faClock,
  faCloud,
  faCommentAlt,
  faDoorOpen,
  faEdit,
  faExclamationCircle,
  faExternalLinkAlt,
  faFileExport,
  faFileImport,
  faGlobe,
  faHistory,
  faIdBadge,
  faKey,
  faLayerGroup,
  faLightbulb,
  faList,
  faMinus,
  faPencilAlt,
  faPlus,
  faQuestionCircle,
  faSearchPlus,
  faSignOutAlt,
  faSort,
  faSortDown,
  faSortUp,
  faStar,
  faThermometerHalf,
  faTimes,
  faTint,
  faTools,
  faTrash,
  faUserCircle,
  faUserFriends,
  faUsers,
  faVolumeUp,
  faWalking,
  faWifi
} from '@fortawesome/free-solid-svg-icons'

// eslint-disable-next-line import/extensions
import BootstrapVue from 'bootstrap-vue'
import FlagIcon from 'vue-flag-icon'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Router from 'vue-router'
import Vue from 'vue'
import VueCookie from 'vue-cookie'
import VueMeta from 'vue-meta'
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons'
import kc from 'plugin-vuejs-keycloak'
import { library } from '@fortawesome/fontawesome-svg-core'
import store from '@/store'
import router from './router'
import i18n from './plugins/i18n'
import App from './App'

library.add(
  faTimes,
  faQuestionCircle,
  faHistory,
  faGlobe,
  faUserCircle,
  faKey,
  faSignOutAlt,
  faBuilding,
  faArrowLeft,
  faList,
  faLayerGroup,
  faCalendarAlt,
  faSearchPlus,
  faThermometerHalf,
  faTint,
  faWalking,
  faCloud,
  faLightbulb,
  faUsers,
  faVolumeUp,
  faWifi,
  faPlus,
  faEdit,
  faChartLine,
  faFileExport,
  faFileImport,
  faTrash,
  faSort,
  faSortUp,
  faSortDown,
  faPencilAlt,
  faChevronLeft,
  faChevronRight,
  faExclamationCircle,
  faCheckCircle,
  faIdBadge,
  faUserFriends,
  faCaretDown,
  faCaretUp,
  faMinus,
  faDoorOpen,
  faClock,
  faExternalLinkAlt,
  faTools,
  faStar,
  farStar,
  faCommentAlt
)
Vue.component('font-awesome-icon', FontAwesomeIcon)
// Tell Vue to use the plugin
Vue.use(VueCookie)
Vue.use(FlagIcon)
Vue.use(Router)
Vue.use(BootstrapVue)
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})
if (process.env.NODE_ENV === 'development') {
  Vue.config.devtools = true
} else {
  Vue.config.productionTip = false
}

Vue.filter(
  'ucfirst',
  str => str.charAt(0).toUpperCase() + str.substring(1)
)

// eslint-disable-next-line
new Vue({
  el: '#app',
  router,
  kc,
  store,
  i18n,
  components: { App },
  render(createElement) {
    return createElement('App')
  }
})
