export const getDefaultState = () => ({
  publicScreensTokens: [],
  dailyReservationsThemeSettings: {
    themeSettings: {},
    logoUrl: ''
  }
})

const state = getDefaultState()

export default state
