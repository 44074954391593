<!-- eslint-disable vue/no-v-html -->

<template>
  <div class="page-container">
    <section class="section-settings">
      <div class="container">
        <div class="title-wrap">
          <h1 class="title-1">
            {{ $t('locations') }}
          </h1>
          <!--  comment timepicker button on building page 
          because some users confused due to this button-->
          <!-- <b-button
            it can check the length or reservationsEnabled 
            v-if="canReserveInAnyBuilding"
            class="button-timepicker"  
            variant="primary"
            @click="$bvModal.show('modal-changedate')"
          >
            <span>
              {{
                startTime
                  | moment(
                    'timezone',
                    buildingTimezone,
                    'D MMMM HH:mm'
                  )
              }}
              -
              {{
                endTime
                  | moment('timezone', buildingTimezone, 'HH:mm')
              }}
            </span>
            <font-awesome-icon icon="calendar-alt" />
          </b-button> -->
        </div>
        <div class="subtitle">
          {{ $t('pleaseChooseLocation') }}
        </div>
        <div class="row locations-list">
          <div v-for="(orgProps, idx) in orgs" :key="`${orgProps.orgName}__${idx}`"
            class="locations-list--item col-md-4 col-sm-12">
            <b-alert v-if="orgProps.showPopUpText && orgProps.popupText" fade show
              style="width: 100%; max-width: 100%; z-index:2000; top: unset;transform: translateY(0%); transform: translateX(-50%);"
              class="m-0 rounded-0 fixed-bottom" variant="warning" dismissible>
              <span v-html="orgProps.popupText" />
            </b-alert>
            <div class="img-cont" :style="{
              backgroundImage: `url(${orgProps.buildingImageUrl
                  ? orgProps.buildingImageUrl
                  : require('@/assets/img/placeholderBuilding.jpg')
                })`
            }" />
            <div class="desc">
              <div class="top">
                <h3 class="title">
                  {{ orgProps.orgName }}
                </h3>
                <div v-if="orgProps.reservationsEnabled" class="seats">
                  <strong>
                    {{ orgProps.availableObjects }}
                    /
                    {{ orgProps.reservableObjects }}
                  </strong>
                  <template v-if="!orgProps.timeslotsEnabled">
                    {{ $t('seatsAvailableNow') }}
                  </template>
                  <template v-if="orgProps.timeslotsEnabled">
                    {{ $t('seatsAvailable') }}
                    {{ $t('nextTimeslot') }}
                  </template>
                </div>
              </div>
              <div class="jcsb">
                <b-button variant="secondary" class="mobile-hide" @click="changePath(orgProps.path)">
                  {{ $t('chooseLocation') }}
                </b-button>
                <a v-if="orgProps.buildingMoreInfoUrl" :href="orgProps.buildingMoreInfoUrl" target="_blank"
                  class="link-more">
                  {{ $t('moreInfo') }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <reservation-timepicker-modal :raw-timeslots="allBuildingsTimeslots" mode="global" />
  </div>
</template>

<script>
import _pick from 'lodash/pick'
import _each from 'lodash/each'
import moment from 'moment'
import { mapActions, mapState, mapGetters } from 'vuex'

import ReservationTimepickerModal from '@/components/modals/ReservationTimepickerModal'

import OkkuApi from '@/services/OkkuApi'
import {
  SET_INFO_URL,
  SET_LOGO_URL,
  SET_READY,
  UPDATE_POP_UP_TEXT_STATUS
} from '@/store/modules/common/mutationTypes'

export default {
  name: 'BuildingsPage',
  components: {
    ReservationTimepickerModal
  },
  data() {
    return {
      orgs: {},
      allBuildingsTimeslots: [],
      startTime: null,
      endTime: null,
      selectedPath: null,
      moment
    }
  },
  computed: {
    ...mapState('common', ['timepicker']),
    ...mapState('common', {
      userInfo: '$userInfo',
      readyToChangeRoute: 'ready'
    }),
    ...mapGetters('common', [
      'canReserveInAnyBuilding',
      'buildingTimezone'
    ])
  },
  watch: {
    readyToChangeRoute(isReady) {
      if (
        isReady &&
        this.selectedPath &&
        this.orgs[this.selectedPath]
      ) {
        if (this.orgs[this.selectedPath].reservationsEnabled) {
          this.$router.push({
            name: 'WorkplaceReservationPage'
          })
        } else {
          this.$router.push({ name: 'SensorsDataPage' })
        }
      }
    },
    userInfo() {
      this.orgs = this.userInfo.groups
      this.initialize()
    },
    timepicker(nextState, prevState) {
      if (
        prevState.date !== null &&
        nextState.date !== prevState.date
      ) {
        this.getBuildingsTimeslots(nextState.date)
        return
      }
      if (
        this.startTime !== nextState.startTime ||
        this.endTime !== nextState.endTime
      ) {
        this.startTime = nextState.startTime
        this.endTime = nextState.endTime
        this.getBuildingsAvailability()
      }
    }
  },
  async beforeDestroy() {
    // Update store
    const orgPaths = []
    const orgs = { ...this.orgs }

    Object.entries(orgs).forEach(org => {
      if (org[1].showPopUpText && org[1].popupText) {
        org[1].showPopUpText = false
        orgPaths.push(`/${org[1].path.split('/')[2]}`)
      }
    })

    this.$store.commit(`common/${UPDATE_POP_UP_TEXT_STATUS}`, orgs)
  },
  mounted() {
    if (this.userInfo && this.userInfo.groups) {
      this.orgs = this.userInfo.groups
      this.initialize()
    }

    if (Object.values(this.timepicker).every(Boolean)) {
      this.startTime = this.timepicker.startTime
      this.endTime = this.timepicker.endTime
      this.getBuildingsAvailability()
    }
  },
  methods: {
    ...mapActions('common', ['updateOrganization']),

    async initialize() {
      switch (Object.keys(this.orgs).length) {
        case 0: {
          this.$router.push({ name: 'NoAccessPage' })
          break
        }
        case 1: {
          const fallbackPath = Object.keys(this.orgs)[0]
          await this.changePath(fallbackPath)
          break
        }
        default: {
          let date = this.moment()
            .tz(this.buildingTimezone)
            .toISOString()

          if (this.timepicker?.date) {
            date = this.timepicker.date
          }

          this.getBuildingsTimeslots(date)
          break
        }
      }
      if (this.$store.state.common.$organisation) {
        const { logoUrl, infoUrl } = Object.values(this.orgs).find(
          org => org.path === this.$store.state.common.$organisation
        )
        if (logoUrl != null) {
          this.$store.commit(`common/${SET_LOGO_URL}`, logoUrl)
          this.$store.commit(`common/${SET_INFO_URL}`, infoUrl)
        }
      }
    },
    async changePath(path) {
      this.selectedPath = path

      await this.updateOrganization(path)

      if (this.orgs[this.selectedPath].reservationsEnabled) {
        this.$router.push({
          name: 'WorkplaceReservationPage'
        })
      } else {
        this.$router.push({ name: 'SensorsDataPage' })
      }

      this.$store.commit(`common/${SET_READY}`, true)
    },
    async getBuildingsAvailability() {
      const orgPaths = Object.keys(this.orgs)

      const availabilityPromises = []
      orgPaths.forEach(path => {
        if (this.orgs[path].reservationsEnabled) {
          const [org, building] = path.split('/').filter(Boolean)
          availabilityPromises.push(
            OkkuApi.getBuildingAvailability(
              org,
              building,
              _pick(this, ['startTime', 'endTime'])
            )
          )
        } else {
          availabilityPromises.push(
            Promise.resolve({
              availableObjects: 0,
              reservableObjects: 0
            })
          )
        }
      })
      const availabilityData = await Promise.all(availabilityPromises)

      const orgsUnsorted = orgPaths.map((path, idx) => [
        path,
        {
          ...this.orgs[path],
          ...availabilityData[idx]
        }
      ])
      const orgsSorted = orgsUnsorted.sort((a, b) => {
        const valueToCompareA =
          a[1].availableObjects === 0 && a[1].reservableObjects === 0
            ? 0
            : a[1].availableObjects / a[1].reservableObjects
        const valueToCompareB =
          b[1].availableObjects === 0 && b[1].reservableObjects === 0
            ? 0
            : b[1].availableObjects / b[1].reservableObjects

        return valueToCompareB - valueToCompareA
      })

      this.orgs = Object.fromEntries(orgsSorted)
    },
    async getBuildingsTimeslots(date) {
      const timeslotsPromises = []
      _each(this.orgs, (org, orgPath) => {
        if (org.reservationsEnabled) {
          timeslotsPromises.push(
            OkkuApi.getAvailableTimeslots(orgPath.slice(1), date)
          )
        }
      })
      const response = await Promise.all(timeslotsPromises)

      this.allBuildingsTimeslots = response.flat()
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../assets/scss/globals/vars.scss';
@import '../assets/scss/globals/mixins.scss';
@import '../assets/scss/globals/extend.scss';

.title-wrap {
  display: flex;
  align-items: center;

  @include r(767) {
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;
  }
}

.subtitle {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.button-timepicker {
  display: flex;
  align-items: center;
  margin-left: 1rem;

  svg {
    margin-left: 0.5rem;
    margin-bottom: 0.1rem;
  }

  @include r(767) {
    margin-left: 0;
    align-self: stretch;
    justify-content: center;
  }
}

.locations-list {
  &--item {
    margin-bottom: 30px;
    flex-flow: column;
    display: flex;

    .img-cont {
      height: 200px;
      background-position: center;
      background-size: cover;
      background-color: #efefef;
    }

    .top {
      margin-bottom: 15px;
    }

    .desc {
      background-color: #fff;
      padding: 20px;
      border: 1px solid hsla(0, 0%, 89.8%, 0.5);
      border-top: none;
      color: #737478;
      font-weight: 300;
      font-size: 12px;
      flex: 1;
      display: flex;
      flex-flow: row wrap;

      .jcsb {
        align-items: center;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-self: flex-end;
        width: 100%;
      }

      strong {
        color: #212529;
        font-weight: 500;
      }

      .title {
        font-size: 18px;
        font-weight: 500;
        color: #212529;
        margin-bottom: 0;
        width: 100%;
      }

      address {
        margin-top: 10px;
        margin-bottom: 15px;
        width: 100%;

        a {
          color: inherit;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .top {
        width: 100%;
      }

      .btn.btn.btn {
        font-size: 12px;
        padding: 8px 15px;
      }

      .seats {
        margin-top: 5px;
      }

      .link-more {
        color: #5440a5;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
</style>
