<template>
  <b-modal
    v-model="show"
    v-bind="$attrs"
    @hidden="$emit('hidden')"
    @shown="payload => $emit('shown', payload)"
  >
    <slot />
  </b-modal>
</template>

<script>
export default {
  name: 'BootstrapModalHOC',
  props: {
    shouldShow: {
      type: Boolean,
      default: false
    },
    onEnterKeyPress: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      handleShowModal: () => {},
      handleHideModal: () => {},
      show: this.shouldShow
    }
  },
  watch: {
    shouldShow() {
      this.show = this.shouldShow
    }
  },
  mounted() {
    const keyupHandler = event => {
      const { key } = event
      if (key === 'Enter') {
        this.onEnterKeyPress(event)
      }
    }
    const modalId = this.$attrs.id

    this.handleShowModal = ({ componentId }) => {
      if (componentId === modalId) {
        document
          .getElementById(modalId)
          .addEventListener('keyup', keyupHandler)
      }
    }
    this.handleHideModal = ({ componentId }) => {
      if (componentId === modalId) {
        document
          .getElementById(modalId)
          .removeEventListener('keyup', keyupHandler)
      }
    }

    this.$root.$on('bv::modal::shown', this.handleShowModal)
    this.$root.$on('bv::modal::hide', this.handleHideModal)
  },
  beforeDestroy() {
    this.$root.$off('bv::modal::shown', this.handleShowModal)
    this.$root.$off('bv::modal::hide', this.handleHideModal)
  }
}
</script>
