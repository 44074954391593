import _filter from 'lodash/filter'
import _get from 'lodash/get'

const getters = {
  enableAllUsersToInviteGuests(state) {
    if (
      state &&
      state.$userInfo &&
      state.$organisation &&
      state.$userInfo.groups
    ) {
      return state.$userInfo.groups[state.$organisation]
        .enableAllUsersToInviteGuests
    }
    return false
  },
  allowDeskReservationSubject(state) {
    if (
      state &&
      state.$userInfo &&
      state.$organisation &&
      state.$userInfo.groups
    ) {
      return state.$userInfo.groups[state.$organisation]
        .allowDeskReservationSubject
    }
    return false
  },
  allowRoomReservationSubject(state) {
    if (
      state &&
      state.$userInfo &&
      state.$organisation &&
      state.$userInfo.groups
    ) {
      return state.$userInfo.groups[state.$organisation]
        .allowRoomReservationSubject
    }
    return false
  },
  hasOrganisationAccess: state =>
    state &&
    state.$userInfo &&
    state.$userInfo.groups &&
    Object.keys(state.$userInfo.groups).length > 0,
  reservationsEnabled: state =>
    !state.organisationModules || state.organisationModules.workplace,
  canReserveInAnyBuilding: state =>
    state &&
    state.$userInfo &&
    _filter(state.$userInfo.groups, {
      reservationsEnabled: true
    }).length,
  sensorsEnabled: state =>
    _get(state, 'organisationModules.sensor') ||
    (state.dashboardSettings.hasSensors &&
      (state.$isDashboardAdmin ||
        state.$isCustomerAdmin ||
        state.$isSensorViewer)),
  activeGroup: state => {
    if (
      state &&
      state.$userInfo &&
      state.$userInfo.groups &&
      state.$organisation
    ) {
      return state.$userInfo.groups[state.$organisation] || {}
    }
    return {}
  },
  loggedInUsersRoles: state => state.$userInfo?.roles,
  isAdmin: state => state.$isCustomerAdmin || state.$isDashboardAdmin,
  isDashboardAdmin: state => state.$isDashboardAdmin,
  isCustomerAdmin: state => state.$isCustomerAdmin,
  isTeamLead: state => state.$isTeamLead,
  isSensorViewer: state => state.$isSensorViewer,
  isAdminOrCoordinator: state =>
    state.$isCustomerAdmin ||
    state.$isDashboardAdmin ||
    state.$isCustomerCoordinator,
  hasViewCustomAssetRole: state => state.$hasViewCustomAssetRole,
  // findMyColleaguesEnabled: (state, funcs) =>
  //   !!funcs.activeGroup.findMyColleagues,
  findMyColleaguesEnabled: state => state.$settings.findMyColleagues,
  allowPrivateReservations: state =>
    state.$settings.allowPrivateReservations,
  buildingTimezone: (state, funcs) =>
    funcs.activeGroup.timezone || 'Europe/Amsterdam',
  insightsEnabled: state =>
    (!state.organisationModules ||
      state.organisationModules.insights) &&
    (state.$isDashboardAdmin ||
      state.$isCustomerAdmin ||
      state.$isInsightsUser),
  isMobileDevice: state => state.isMobile,
  userDetail: state => state.$userInfo.user,
  authorisations: state => state.authorisations,
  getOrganisation: state => state.$organisation,
  getBuildings: state => state.$userInfo.groups,
  allowRoomReservationSubjects(state) {
    if (
      state &&
      state.$userInfo &&
      state.$organisation &&
      state.$userInfo.groups
    ) {
      return state.$userInfo.groups[state.$organisation]
        .allowRoomReservationSubjects
    }
    return false
  },
  allowDeskReservationSubjects(state) {
    if (
      state &&
      state.$userInfo &&
      state.$organisation &&
      state.$userInfo.groups
    ) {
      return state.$userInfo.groups[state.$organisation]
        .allowDeskReservationSubjects
    }
    return false
  },
  getSensorHistoryParams: state => state.sensorHistoryParams,
  getOldOpeningHours: state => state.oldOpeningHours,
  getParentCheckBoxState: state => state.toggleParentCheckbox,
  reservationsettings: state => state.$settings.reservationSettings
}

export default getters
