import {
  SET_PUBLIC_SCREENS_TOKENS,
  SET_DAILY_RESERVATIONS_THEME_SETTINGS,
  RESET_MODULE_STATE
} from './mutationTypes'
import { getDefaultState } from './state'

const mutations = {
  [SET_PUBLIC_SCREENS_TOKENS](state, payload) {
    state.publicScreensTokens = payload
  },

  [SET_DAILY_RESERVATIONS_THEME_SETTINGS](state, payload) {
    state.dailyReservationsThemeSettings.themeSettings.backgroundColor =
      payload.themeSettings.background_color
    state.dailyReservationsThemeSettings.themeSettings.regularTextColor =
      payload.themeSettings.regular_text_color
    state.dailyReservationsThemeSettings.themeSettings.tableColor =
      payload.themeSettings.table_color
    state.dailyReservationsThemeSettings.themeSettings.titleBarColor =
      payload.themeSettings.title_bar_color
    state.dailyReservationsThemeSettings.themeSettings.titleTextColor =
      payload.themeSettings.title_text_color
    state.dailyReservationsThemeSettings.themeSettings.welcomeMessage =
      payload.themeSettings.welcome_message
    state.dailyReservationsThemeSettings.themeSettings.fontSize =
      payload.themeSettings.font_size
    state.dailyReservationsThemeSettings.logoUrl = payload.logoUrl
  },

  [RESET_MODULE_STATE](state) {
    Object.assign(state, getDefaultState())
  }
}

export default mutations
