<template>
  <div v-if="show">
    <main-nav class="sticky-nav" />
    <div class="spacer" />
    <mobile-bottom-nav />
    <secondary-nav />
    <account-modal
      v-if="keycloakUserProfile.attributes !== undefined"
      :userinfo="$userInfo"
      :keycloak-user-profile="keycloakUserProfile"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'

import AccountModal from '../modals/AccountModal'
import SecondaryNav from './navigation/SecondaryNav'
import MainNav from './navigation/MainNav'
import MobileBottomNav from './navigation/MobileBottomNav'

export default {
  name: 'AppHeader',
  components: {
    AccountModal,
    SecondaryNav,
    MainNav,
    MobileBottomNav
  },
  data() {
    return {}
  },
  computed: {
    show() {
      return !this.$route.meta.hideHeader
    },
    ...mapState('common', ['keycloakUserProfile', '$userInfo'])
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/globals/vars.scss';
@import '@/assets/scss/globals/mixins.scss';
@import '@/assets/scss/globals/extend.scss';

.spacer {
  /* Header should always be this height */
  height: 123px;
  @include r(1200) {
    height: 113px;
  }
}
.sticky-nav {
  left: 0;
  right: 0;
  position: fixed;
  z-index: 20;
}
</style>
