export const OCCUPANCY = {
  FREE: 'FREE',
  RESERVED: 'RESERVED',
  OCCUPIED: 'OCCUPIED',
  FIXED: 'FIXED',
  MOVEMENT: 'MOVEMENT'
}

export const OBJECTS_TYPES = {
  DESK: 'DESK',
  FLOOR: 'FLOOR',
  ROOM: 'ROOM'
}

export const keycloackClientRoles = {
  dashboardAdmin: 'Dashboard-admin',
  customerAdmin: 'Customer-admin',
  customerTeamLead: 'Customer-team-lead',
  customerCoordinator: 'Customer-coordinator',
  insights: 'Insights'
}

export const ACTIONS = {
  REJECTED: 'REJECTED',
  APPROVED: 'APPROVED'
}

export const DATE_TIME_NAVIGATION = {
  NEXT: 'next',
  PREVIOUS: 'prev'
}

export const ONLY_NUMBER = {
  number: /['~`!@#$%*"a-zA-Z|^&()_={}[\]:;,.<>+\\/?-]/g
}

export const SCROLLER = {
  RANGE: 32
}

export const COMMUNICATION_CONSTANTS = {
  REWARDS_PAGE_INFO_FROM_ACCOUNT_PAGE_TO_MAIN_NAV_BAR:
    'message_from_account_modal_about_rewards_page',
  REWARDS_PAGE_STATUS_INFO: 'message_about_rewards_page_status'
}
export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const colors = [
  '#61bd4f',
  '#f2d600',
  '#ff9f1a',
  '#eb5a46',
  '#c377e0',
  '#0079bf',
  '#00c2e0',
  '#51e898',
  '#ff78cb',
  '#344563'
]

export class PublicScreenTypesEnum {
  static DAILY_RESERVATION = 'dailyReservation'
  static PUBLIC_SCREEN = 'publicScreen'
}
