import {
  SET_INFO_URL,
  SET_LOGO_URL,
  SET_USER_INFO
} from '@/store/modules/common/mutationTypes'
import AllReservationsPage from '@/pages/admin/AllReservationsPage'
import BuildingsPage from '@/pages/BuildingsPage'
import KeycloakAuth from '@/services/KeycloakAuth'
import MobileConfirmation from '@/pages/MobileConfirmation'
import MyAbsencesPage from '@/pages/MyAbsencesPage'
import NoAccessPage from '@/pages/NoAccessPage'
import OkkuApi from '@/services/OkkuApi'
import Router from 'vue-router'
import SensorPage from '@/pages/SensorPage'
import StartPage from '@/pages/StartPage'
import Vue from 'vue'
import store from '@/store'
import TodayReservationsPage from '@/pages/TodayReservationsPage'

// Lazy loading components whihc are not needed on initial page load
const FixedDeskEmployeesPage = () =>
  import('@/pages/admin/FixedDeskEmployeesPage')
const TimeslotsPage = () => import('@/pages/admin/TimeslotsPage')
const AuthorisationsPage = () =>
  import('@/pages/admin/AuthorisationsPage')
const EditAbsencesPage = () =>
  import('@/pages/admin/EditAbsencesPage')

const SensorsPage = () => import('@/pages/admin/SensorsPage')
const FloorsPage = () => import('@/pages/admin/FloorsPage')
const RoomsPage = () => import('@/pages/admin/RoomsPage')
const DesksPage = () => import('@/pages/admin/DesksPage')
const TodayScreens = () => import('@/pages/admin/TodayScreens')
const Rewards = () => import('@/pages/Rewards.vue')
const UsersPage = () => import('@/pages/admin/UsersPage')
const FileStatusesPage = () =>
  import('@/pages/admin/UploadedFileStatuses.vue')
const ReservationAssetsPage = () =>
  import('@/pages/admin/ReservationAssetsPage')

const WorkplaceReservationPage = () =>
  import('@/pages/WorkplaceReservationPage')
const KioskReservationsPage = () =>
  import('@/pages/KioskReservationsPage.vue')

const BuildingSettingsPage = () =>
  import('@/pages/admin/BuildingSettingsPage')

const SensorsDataPage = () => import('@/pages/SensorsDataPage')

const MyReservationsPage = () => import('@/pages/MyReservationsPage')

const FindMyColleaguesPage = () =>
  import('@/pages/FindMyColleaguesPage')

const ReservationDetails = () =>
  import('@/reports/ReservationDetails.vue')
const UserSummaries = () => import('@/reports/UserSummaries.vue')
const AssetsAvailability = () =>
  import('@/reports/AssetsAvailability.vue')

const ReservationsAndCancellationsStatistics = () =>
  import('@/reports/ReservationsAndCancellationsStatistics.vue')
const HourlyAvailability = () =>
  import('@/reports/HourlyAvailability.vue')
const EmailTemplatesPage = () =>
  import('@/pages/admin/EmailTemplatesPage')
const SensorHistory = () => import('@/reports/SensorHistory.vue')
const ClimateIndicators = () =>
  import('@/reports/ClimateIndicators.vue')
const FixedDesks = () => import('@/reports/FixedDesks.vue')

const OrganisationsAdminPage = () =>
  import('@/pages/admin/OrganisationsPage.vue')
const BuildingsAdminPage = () =>
  import('@/pages/admin/BuildingsPage.vue')

Vue.use(Router)

const base = ''

const routes = [
  {
    path: '/',
    name: 'StartPage',
    component: StartPage,
    meta: {
      requiresAuth: false,
      title: 'Okku login',
      hideHeader: true,
      hideFooter: true
    }
  },
  {
    name: 'StartPage',
    path: '/start/:org/:building',
    component: StartPage,
    meta: {
      requiresAuth: false,
      title: 'Okku login',
      hideHeader: true,
      hideFooter: true
    }
  },
  {
    path: '/sensor/:id',
    name: 'SensorPage',
    component: SensorPage,
    meta: {
      requiresAuth: false,
      hideHeader: true,
      hideFooter: true
    }
  },
  {
    path: '/workplace/desks',
    name: 'WorkplaceReservationPage',
    component: WorkplaceReservationPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/workplace/admin',
    name: 'DesksPage',
    component: DesksPage,
    meta: {
      requiresAuth: true,
      showSubmenu: true
    },
    beforeEnter: isAdminOrCoordinator
  },
  {
    path: '/workplace/fixed-desk-employees',
    name: 'FixedDeskEmployeesPage',
    component: FixedDeskEmployeesPage,
    meta: {
      requiresAuth: true,
      showSubmenu: true
    },
    beforeEnter: isAdminOrCoordinator
  },
  {
    path: '/workplace/absences/:username',
    name: 'EditAbsencesPage',
    component: EditAbsencesPage,
    meta: {
      requiresAuth: true,
      showSubmenu: true
    },
    props: true,
    beforeEnter: isAdminOrCoordinator
  },
  {
    path: '/workplace/buildingSettings',
    name: 'BuildingSettingsPage',
    component: BuildingSettingsPage,
    meta: {
      requiresAuth: true,
      showSubmenu: true
    },
    beforeEnter: isAdminOrCoordinator
  },
  {
    path: '/workplace/authorisations',
    name: 'AuthorisationsPage',
    component: AuthorisationsPage,
    meta: {
      requiresAuth: true,
      showSubmenu: true
    }
  },
  {
    path: '/workplace/floors',
    name: 'FloorsPage',
    component: FloorsPage,
    meta: {
      requiresAuth: true,
      showSubmenu: true
    },
    beforeEnter: isAdmin
  },
  {
    path: '/workplace/rooms',
    name: 'RoomsPage',
    component: RoomsPage,
    meta: {
      requiresAuth: true,
      showSubmenu: true
    },
    beforeEnter: isAdmin
  },
  {
    path: '/workplace/sensors',
    name: 'SensorsPage',
    component: SensorsPage,
    meta: {
      requiresAuth: true,
      showSubmenu: true
    }
  },
  {
    path: '/workplace/sensors-data',
    name: 'SensorsDataPage',
    component: SensorsDataPage,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.getters['common/sensorsEnabled']) {
        next()
      } else {
        next('/')
      }
    }
  },
  {
    path: '/workplace/reservationAssets',
    name: 'ReservationAssetsPage',
    component: ReservationAssetsPage,
    meta: {
      requiresAuth: true,
      showSubmenu: true
    }
  },
  {
    path: '/workplace/my-reservations',
    name: 'MyReservationsPage',
    meta: { requiresAuth: true },
    component: MyReservationsPage
  },
  {
    path: '/workplace/my-absences',
    name: 'MyAbsencesPage',
    meta: { requiresAuth: true },
    component: MyAbsencesPage
  },
  {
    path: '/workplace/reservations',
    name: 'AllReservationsPage',
    meta: {
      requiresAuth: true,
      showSubmenu: true
    },
    component: AllReservationsPage,
    beforeEnter: isAdminOrCoordinator
  },
  {
    path: '/workplace/daily-reservations/:org/:orgPath',
    name: 'TodayReservationsPage',
    component: TodayReservationsPage,
    meta: {
      requiresAuth: false,
      hideHeader: true,
      hideFooter: true
    }
  },
  {
    path: '/workplace/kiosk/:org/:building',
    name: 'KioskReservationsPage',
    component: KioskReservationsPage,
    meta: {
      requiresAuth: false,
      hideHeader: true,
      hideFooter: true,
      hideFeedback: true
    }
  },
  {
    path: '/workplace/today-screens',
    name: 'TodayScreens',
    component: TodayScreens,
    meta: {
      showSubmenu: true,
      requiresAuth: true
    },
    beforeEnter: isAdmin
  },
  {
    path: '/workplace/rewards',
    name: 'Rewards',
    component: Rewards,
    meta: {
      requiresAuth: true,
      showSubmenu: !!isAdmin
    }
  },
  {
    path: '/workplace/find-my-colleague',
    name: 'FindMyColleaguesPage',
    meta: {
      requiresAuth: true,
      showSubmenu: false
    },
    component: FindMyColleaguesPage
  },
  {
    path: '/workplace/no-access',
    name: 'NoAccessPage',
    meta: { requiresAuth: true },
    component: NoAccessPage
  },
  {
    path: '/workplace/unauthorized',
    name: 'Unauthorized',
    meta: { requiresAuth: false },
    component: NoAccessPage
  },
  { path: '*', component: NoAccessPage },
  {
    path: '/workplace/buildings',
    name: 'BuildingsAdminPage',
    component: BuildingsAdminPage,
    meta: { requiresAuth: true },
    beforeEnter: isDashboardAdmin
  },
  {
    path: '/workplace/organisations',
    name: 'OrganisationsAdminPage',
    component: OrganisationsAdminPage,
    meta: { requiresAuth: true },
    beforeEnter: isDashboardAdmin
  },
  {
    path: '/workplace/reservation-status/:hash',
    name: 'MobileConfirmation',
    meta: {
      requiresAuth: false,
      hideHeader: true,
      hideFooter: true
    },
    component: MobileConfirmation
  },
  {
    path: '/workplace/timeslots',
    name: 'TimeslotsPage',
    meta: {
      requiresAuth: true,
      showSubmenu: true
    },
    component: TimeslotsPage
  },
  {
    path: '/workplace',
    name: 'BuildingsPage',
    meta: { requiresAuth: true },
    component: BuildingsPage
  },
  {
    path: '/workplace/users',
    name: 'UsersPage',
    meta: {
      requiresAuth: true,
      showSubmenu: true
    },
    component: UsersPage,
    beforeEnter: isAdminOrCoordinator
  },
  {
    path: '/workplace/files/status',
    name: 'FileStatusesPage',
    meta: {
      requiresAuth: true,
      showSubmenu: true
    },
    component: FileStatusesPage,
    beforeEnter: isAdminOrCoordinator
  },
  {
    path: '/workplace/email-templates',
    name: 'EmailTemplatesPage',
    meta: {
      requiresAuth: true,
      showSubmenu: true
    },
    component: EmailTemplatesPage
  },
  {
    path: '/workplace/insights-user-summaries',
    name: 'UserSummaries',
    meta: {
      requiresAuth: true,
      showSubmenu: false
    },
    component: UserSummaries
  },
  {
    path: '/workplace/insights-asset-availability',
    name: 'AssetsAvailability',
    meta: {
      requiresAuth: true,
      showSubmenu: false
    },
    component: AssetsAvailability
  },
  {
    path: '/workplace/insights-hourly-availability',
    name: 'HourlyAvailability',
    meta: {
      requiresAuth: true,
      showSubmenu: false
    },
    component: HourlyAvailability
  },
  {
    path: '/workplace/insights-hourly-cancellations',
    name: 'ReservationsAndCancellationsStatistics',
    meta: {
      requiresAuth: true,
      showSubmenu: false
    },
    component: ReservationsAndCancellationsStatistics
  },
  {
    path: '/workplace/insights-reservations',
    name: 'ReservationDetails',
    meta: {
      requiresAuth: true,
      showSubmenu: false
    },
    component: ReservationDetails
  },
  {
    path: '/workplace/insights-fixed-desks',
    name: 'FixedDesks',
    meta: { requiresAuth: true, showSubmenu: false },
    component: FixedDesks
  },
  {
    path: '/workplace/sensorHistory',
    name: 'SensorHistory',
    meta: {
      requiresAuth: true,
      showSubmenu: false
    },
    component: SensorHistory
  },
  {
    path: '/workplace/climateIndicators',
    name: 'ClimateIndicators',
    meta: {
      requiresAuth: true
    },
    component: ClimateIndicators
  }
]
const router = new Router({
  mode: 'history',
  base,
  routes
})

router.beforeResolve(async (to, from, next) => {
  if (
    store.state.common.$organisation !== null &&
    localStorage.getItem('BUILDING') !== null &&
    to.meta.requiresAuth
  ) {
    // If building is known from local storage, but org not set: Set org
    await store.dispatch(
      'common/updateOrganization',
      localStorage.BUILDING
    )
  } else {
    // If organisation or building not set, load them from domain info and save them in the store
    let { logoUrl, infoUrl } = await OkkuApi.getDomainInformation(to)
    infoUrl =
      infoUrl ||
      'https://okku.atlassian.net/servicedesk/customer/portals'
    logoUrl =
      logoUrl || 'https://static.okku.io/assets/okku-logo-2021.svg'

    store.commit(`common/${SET_LOGO_URL}`, logoUrl)
    store.commit(`common/${SET_INFO_URL}`, infoUrl)
  }

  // Check if auth is not needed or already logged on
  if (
    Object.keys(store.state.common.$userInfo).length ||
    to.meta.requiresAuth !== true
  ) {
    next()
    return
  }
  // Check if auth is still valid
  OkkuApi.getMyBuildings().then(async data => {
    try {
      const { refresh } = data
      if (refresh) {
        const { idpHint } = await OkkuApi.getDomainInformation()
        KeycloakAuth.init(next, idpHint)
      }
      store.commit(`common/${SET_USER_INFO}`, data)
      next()
    } catch (error) {
      console.log(error)
    }
  })
})

router.beforeEach(async (to, from, next) => {
  try {
    if (to.meta.requiresAuth === true) {
      if (store.state.common.authenticated) {
        next()
      } else {
        const { idpHint } = await OkkuApi.getDomainInformation()
        KeycloakAuth.init(next, idpHint)
      }
    } else {
      next()
    }
  } catch (error) {
    console.log(error)
  }
})

function isDashboardAdmin(to, from, next) {
  if (KeycloakAuth.isDashboardAdmin()) {
    next()
  } else {
    next('/')
  }
}

function isAdminOrCoordinator(to, from, next) {
  if (KeycloakAuth.isAdminOrCoordinator()) {
    next()
  } else {
    next('/')
  }
}

function isAdmin(to, from, next) {
  if (KeycloakAuth.isAdmin()) {
    next()
  } else {
    next('/')
  }
}

export default router
