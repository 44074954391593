<template>
  <b-modal
    id="post-review-modal"
    ref="bmodal"
    hide-footer
    :on-enter-key-press="close"
    size="md"
    :title="$t('thank-you')"
    no-close-on-backdrop
    no-close-on-esc
    enter
  >
    <p>
      {{
        $t('also-rate-us-on', {
          reviewPlatform: reviewPlatform.reviewPlatform
        })
      }}
    </p>
    <b-overlay :show="loading" rounded="sm"> </b-overlay>
    <b-row align-h="end">
      <b-button
        size="lg"
        class="mt-3 mr-3"
        variant="primary"
        @click="toReviewPlatform"
      >
        {{
          $t('rate-on', {
            reviewPlatform: reviewPlatform.reviewPlatform
          })
        }}
      </b-button>
    </b-row>
  </b-modal>
</template>
<script>
// import OkkuApi from '@/services/OkkuApi'
import BootstrapModalHOC from '@/components/hocs/BootstrapModal'
import { mapState } from 'vuex'

export default {
  components: {
    'b-modal': BootstrapModalHOC
  },
  props: {},
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState('common', ['reviewPlatform'])
  },
  created() {},
  mounted() {},
  methods: {
    close() {
      this.$bvModal.hide('post-review-modal')
    },
    toReviewPlatform() {
      window.open(this.reviewPlatform.reviewUrl)
      this.close()
    }
  }
}
</script>
