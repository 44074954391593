<template>
  <div>
    <review-modal />
    <post-review-modal />
    <button
      type="submit"
      class="btn btn-primary btn-lg floating m-4 d-none d-lg-block"
      @click="showModal()"
    >
      {{ $t('review-us') }}
    </button>
  </div>
</template>

<script>
import ReviewModal from '../modals/ReviewModal'
import PostReviewModal from '../modals/PostReviewModal'

export default {
  name: 'ReviewComponent',
  components: {
    ReviewModal,
    PostReviewModal
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    showModal() {
      this.$bvModal.show('review-modal')
    }
  }
}
</script>
<style lang="scss" scoped>
.floating {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 10;
}
</style>
