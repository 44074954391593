var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.renderKey,style:({
    backgroundColor: _vm.isApproved
      ? _vm.dailyReservationsThemeSettings.themeSettings.backgroundColor
      : '#ffffff',
    height: '100%',
    overflow: 'hidden auto'
  })},[_c('div',{ref:"header",staticClass:"main-header",style:({
      backgroundColor:
        _vm.dailyReservationsThemeSettings.themeSettings.titleBarColor
    })},[_c('b-row',{staticStyle:{"width":"100%","margin":"0","align-items":"center","padding-left":"1em"},attrs:{"align":"center"}},[_c('b-col',{staticClass:"col-3 text-left"},[_c('img',{staticClass:"img-fluid",staticStyle:{"width":"220px"},attrs:{"src":_vm.dailyReservationsThemeSettings.logoUrl}})]),(_vm.totalPages > 1)?_c('b-col',{staticClass:"col-9 text-left"},[_c('div',{staticClass:"d-flex justify-content-end align-items-center"},[_c('p',{style:({
              color:
                _vm.dailyReservationsThemeSettings.themeSettings
                  .regularTextColor,
              fontSize: ((_vm.dailyReservationsThemeSettings.themeSettings.fontSize) + "px"),
              paddingRight: '1em'
            })},[_vm._v(" "+_vm._s(_vm.$t('page'))+" ")]),_c('Pagination',{attrs:{"current-page":_vm.currentPage,"total-pages":_vm.totalPages,"active-item-color":_vm.dailyReservationsThemeSettings.themeSettings
                .regularTextColor,"disable-arrows":""}})],1)]):_vm._e()],1)],1),_c('div',[(!_vm.isApproved)?_c('div',{staticClass:"page-container",style:({
        paddingTop: '100px',
        paddingBottom: _vm.footerHeight
      })},[_c('b-container',{staticClass:"mt-4",attrs:{"align":"center"}},[_c('b-row',{attrs:{"align":"center"}},[_c('b-col',{staticClass:"col-3"}),_c('b-col',{staticClass:"col-6"},[(_vm.token)?_c('div',[_c('label',{style:({
                  fontSize: ((_vm.dailyReservationsThemeSettings.themeSettings.fontSize) + "px")
                })},[_vm._v(_vm._s(_vm.$t('tokenGenerated')))]),_c('h1',[_vm._v(_vm._s(_vm.token))])]):_vm._e()])],1)],1)],1):_vm._e(),_c('div',{staticClass:"table-container ml-4 mr-4",class:{ 'is-approved': _vm.isApproved },style:({
        paddingBottom: _vm.footerHeight
      })},[_c('div',[_c('table',[_c('thead',{ref:"tableHeader"},[_c('tr',[_c('th',{staticClass:"table-cell-v-paddings",style:({
                  color:
                    _vm.dailyReservationsThemeSettings.themeSettings
                      .titleTextColor,
                  fontSize: ((_vm.dailyReservationsThemeSettings.themeSettings.fontSize) + "px"),
                  paddingLeft: '0.5em'
                })},[_vm._v(" "+_vm._s(_vm.showDesks ? _vm.$t('nameOrSubject') : _vm.$t('reservationSubject'))+" ")]),_c('th',{staticClass:"table-cell-v-paddings",style:({
                  color:
                    _vm.dailyReservationsThemeSettings.themeSettings
                      .titleTextColor,
                  fontSize: ((_vm.dailyReservationsThemeSettings.themeSettings.fontSize) + "px"),
                  width: '30%',
                  marginLeft: '20px'
                })},[_vm._v(" "+_vm._s(_vm.$t('location'))+" ")]),_c('th',{staticClass:"table-cell-v-paddings",style:({
                  color:
                    _vm.dailyReservationsThemeSettings.themeSettings
                      .titleTextColor,
                  fontSize: ((_vm.dailyReservationsThemeSettings.themeSettings.fontSize) + "px"),
                  width: '12%'
                })},[_vm._v(" "+_vm._s(_vm.$t('Time'))+" ")])])]),_c('tbody',[_vm._l((_vm.reservations),function(reservation,index){return [_c('tr',{key:index,staticClass:"todayRow",style:({
                  backgroundColor:
                    _vm.dailyReservationsThemeSettings.themeSettings
                      .tableColor,
                  color:
                    _vm.dailyReservationsThemeSettings.themeSettings
                      .regularTextColor,
                  fontSize: ((_vm.dailyReservationsThemeSettings.themeSettings.fontSize) + "px")
                })},[_c('td',{staticClass:"table-cell-v-paddings",style:({ 'padding-left': '0.5em' })},[_vm._v(" "+_vm._s(reservation.objectType === 'ROOM' ? reservation.reservationSubject : '')+" "+_vm._s(reservation.objectType === 'DESK' ? ((reservation.context.firstName) + " " + (reservation.context.lastName)) : '')+" ")]),_c('td',{staticClass:"table-cell-v-paddings"},[_vm._v(" "+_vm._s(((reservation.objectType === 'ROOM' ? reservation.parentObjectName.trim() : reservation.secondParentObjectName.trim()) + ", " + (reservation.objectType === 'ROOM' ? reservation.objectName : reservation.parentObjectName) + " "))+" ")]),_c('td',{staticClass:"time-cell table-cell-v-paddings"},[_vm._v(" "+_vm._s(_vm.showTime(reservation.startDatetime))+" - "+_vm._s(_vm.showTime(reservation.endDatetime))+" ")])])]}),(!_vm.reservations.length)?_c('tr',{staticClass:"todayRow",style:({
                backgroundColor:
                  _vm.dailyReservationsThemeSettings.themeSettings
                    .tableColor,
                color:
                  _vm.dailyReservationsThemeSettings.themeSettings
                    .regularTextColor,
                fontSize: ((_vm.dailyReservationsThemeSettings.themeSettings.fontSize) + "px")
              })},[_c('td',{style:({ 'padding-left': '0.5em' }),attrs:{"data-label":_vm.$t('noRecordsFound') + ':'}},[_vm._v(" "+_vm._s(_vm.$t('noRecordsFound'))+" ")]),_c('td'),_c('td')]):_vm._e()],2)])])])]),_c('div',{ref:"footer",staticClass:"footer main-footer",style:({
      'background-color':
        _vm.dailyReservationsThemeSettings.themeSettings.titleBarColor
    })},[_c('b-row',[_c('b-col',{staticClass:"col-8 mt-3 pl-4",attrs:{"align":"left"}},[_c('h3',{style:({
            color:
              _vm.dailyReservationsThemeSettings.themeSettings
                .regularTextColor,
            fontSize: ((_vm.dailyReservationsThemeSettings.themeSettings.fontSize) + "px"),
            paddingLeft: '0.5em'
          })},[_vm._v(" "+_vm._s(_vm.dailyReservationsThemeSettings.themeSettings .welcomeMessage)+" ")])]),_c('b-col',{staticClass:"col-4 mt-3 pr-4",attrs:{"align":"right"}},[_c('div',{style:({
            paddingRight: '0.5em',
            fontSize: ((_vm.dailyReservationsThemeSettings.themeSettings.fontSize) + "px")
          })},[_c('h3',{style:({
              color:
                _vm.dailyReservationsThemeSettings.themeSettings
                  .regularTextColor,
              fontSize: ((_vm.dailyReservationsThemeSettings.themeSettings.fontSize) + "px")
            })},[_vm._v(" "+_vm._s(_vm.todayDate)+" ")]),_c('p',{style:({
              color:
                _vm.dailyReservationsThemeSettings.themeSettings
                  .regularTextColor,
              fontSize: ((_vm.dailyReservationsThemeSettings
                .themeSettings.fontSize / 4) + "px")
            })},[_vm._v(" "+_vm._s(("#" + _vm.token))+" ")])])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }