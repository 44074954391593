import { initialEasyPzTransformParams } from '../../constants'

export const getDefaultState = () => ({
  $lastUpdate: Date.now(),
  $userInfo: {},
  $settings: {},
  $isDashboardAdmin: false,
  $hasViewCustomAssetRole: false,
  $isTeamLead: false,
  $isCustomerAdmin: false,
  $isCustomerCoordinator: false,
  $isInsightsUser: false,
  $organisation: '',
  organisationReservationSettings: {},
  dashboardSettings: {},
  organisationUrls: {
    infoUrl: '',
    logoUrl: ''
  },
  organisationModules: {
    sensor: true,
    workplace: false,
    insights: true,
    loading: true
  },
  desks: [],
  floors: [],
  authorisations: [],
  restrictedFloors: [],
  rooms: [],
  dirtyForm: false,
  googleRooms: [],
  azureRooms: [],
  alert: {
    seconds: 0,
    message: '',
    variant: 'success',
    consentLink: ''
  },
  isKiosk: sessionStorage.getItem('kiosk') || false,
  authenticated: false,
  keycloakUserProfile: {},
  ready: true,
  isMobile: false,
  easyPzTransformParams: {
    ...initialEasyPzTransformParams
  },
  timepicker: {
    startTime: null,
    endTime: null,
    date: null
  },
  sensorHistoryParams: {
    sensorId: null,
    fromDate: null,
    endDate: null
  },
  oldOpeningHours: true,
  toggleParentCheckbox: false,
  reviewPlatform: {}
})

const state = getDefaultState()

export default state
