import {
  ALLOW_DESK_RESERVATION_SUBJECT,
  ALLOW_ROOM_RESERVATION_SUBJECT,
  ENABLE_ALL_USERS_TO_INVITE_GUESTS,
  SET_ALERT,
  SET_ALL_USERS_LOADING_STATE,
  SET_BUILDING_USERS,
  SET_LOADING,
  SET_ORG_USERS,
  SET_USER_INFO
} from '@/store/modules/common/mutationTypes'

/* eslint-disable import/no-cycle */
import OkkuApi from '@/services/OkkuApi'

function waitForOrg(rootState) {
  return new Promise((resolve, reject) => {
    if (rootState.common.$organisation) {
      resolve(true)
    }
    const waitInterval = setInterval(() => {
      if (rootState.common.$organisation) {
        clearInterval(waitInterval)
        resolve(true)
      }
    }, 1000)
  })
}

export const actions = {
  async setBuildingSettings({ commit }, { checkboxName, value }) {
    try {
      const data = await OkkuApi.updateBuildingSetting({
        [checkboxName]: value
      })
      if (checkboxName === 'allow_room_reservation_subject') {
        commit(ALLOW_ROOM_RESERVATION_SUBJECT, value, {
          root: true
        })
      }
      if (checkboxName === 'allow_desk_reservation_subject') {
        commit(ALLOW_DESK_RESERVATION_SUBJECT, value, {
          root: true
        })
      }
      return data
    } catch (error) {
      return {
        status: 'danger',
        message: "Failed To Update Building's Setting"
      }
    }
  },
  async setAllUsersToInviteGuests(
    { commit },
    inviteGuestsCheckValue
  ) {
    try {
      const data = await OkkuApi.updateBuildingSetting({
        enable_all_users_to_invite_guests:
          inviteGuestsCheckValue.emitValue
      })
      commit(
        ENABLE_ALL_USERS_TO_INVITE_GUESTS,
        inviteGuestsCheckValue.emitValue,
        { root: true }
      )
      this.userinfo = await OkkuApi.getMyBuildings()
      commit(SET_USER_INFO, this.userinfo, {
        root: true
      })
      return data
    } catch (error) {
      return {
        status: 'danger',
        message: "Failed To Update Building's Setting"
      }
    }
  },
  async getBuildingUsers({ commit, rootState }, payload) {
    commit(SET_LOADING, true)
    await waitForOrg(rootState)
    const data = await OkkuApi.getBuildingUsers(payload.search)
    commit(SET_BUILDING_USERS, data)
    commit(SET_LOADING, false)
  },
  async getOrgUsers({ commit, rootState }, payload) {
    commit(SET_ALL_USERS_LOADING_STATE, true)
    await waitForOrg(rootState)
    const data = await OkkuApi.getOrgUsers(
      payload.search,
      payload.page,
      rootState.$organisation
    )
    commit(SET_ORG_USERS, data)
    commit(SET_ALL_USERS_LOADING_STATE, false)
  },
  async getUserById({ commit }, id) {
    commit(SET_LOADING, true)
    const user = await OkkuApi.getUserById(id)
    commit(SET_LOADING, false)
    return user
  },
  async getUserGroups({ commit }, id) {
    commit(SET_LOADING, true)
    try {
      const groups = await OkkuApi.getUserGroups(id)
      commit(SET_LOADING, false)
      return groups
    } catch (err) {
      commit(SET_LOADING, false)
      commit(
        SET_ALERT,
        {
          seconds: 3,
          text: err,
          variant: 'danger'
        },
        { root: true }
      )
      return []
    }
  },
  async addUserToGroup({ commit }, payload) {
    commit(SET_LOADING, true)
    try {
      const { message } = await OkkuApi.addUserToGroup(
        payload.userId,
        payload.groupId
      )
      commit(SET_LOADING, false)
      commit(
        SET_ALERT,
        {
          seconds: 3,
          text: message,
          variant: 'success'
        },
        { root: true }
      )
      return true
    } catch (error) {
      commit(SET_LOADING, false)
      commit(
        SET_ALERT,
        {
          seconds: 3,
          text: error || 'Error',
          variant: 'danger'
        },
        { root: true }
      )
      return false
    }
  },
  async removeUserFromGroup({ commit }, payload) {
    commit(SET_LOADING, true)
    try {
      const { message } = await OkkuApi.removeUserFromGroup(
        payload.userId,
        payload.groupId
      )
      commit(SET_LOADING, false)
      commit(
        SET_ALERT,
        {
          seconds: 3,
          text: message,
          variant: 'success'
        },
        { root: true }
      )
      return true
    } catch (error) {
      commit(SET_LOADING, false)
      commit(
        SET_ALERT,
        {
          seconds: 3,
          text: error || 'Error',
          variant: 'danger'
        },
        { root: true }
      )
      return false
    }
  },
  async removeUser({ commit }, userId) {
    commit(SET_LOADING, true)
    try {
      const { message } = await OkkuApi.removeUser(userId)
      commit(SET_LOADING, false)
      commit(
        SET_ALERT,
        {
          seconds: 3,
          text: message,
          variant: 'success'
        },
        { root: true }
      )
      return true
    } catch (error) {
      commit(SET_LOADING, false)
      commit(
        SET_ALERT,
        {
          seconds: 3,
          text: error || 'Error',
          variant: 'danger'
        },
        { root: true }
      )
      return false
    }
  },
  async updateUser({ commit }, user) {
    commit(SET_LOADING, true)
    try {
      const { message } = await OkkuApi.updateUser({
        id: user.id,
        attributes: user.attributes || {}
      })
      commit(SET_LOADING, false)
      commit(
        SET_ALERT,
        {
          seconds: 3,
          text: message,
          variant: 'success'
        },
        { root: true }
      )
    } catch (error) {
      commit(SET_LOADING, false)
      commit(
        SET_ALERT,
        {
          seconds: 3,
          text: error,
          variant: 'danger'
        },
        { root: true }
      )
    }
  }
}

export const mutations = {
  [SET_BUILDING_USERS]: (state, users) => {
    state.buildingUsers = users
  },
  [SET_ORG_USERS]: (state, users) => {
    state.orgUsers = users
  },
  [SET_LOADING]: (state, isLoading) => {
    state.loading = isLoading
  },
  [SET_ALL_USERS_LOADING_STATE]: (state, loading) => {
    state.fetchingAllUsers = loading
  }
}

export const state = {
  buildingUsers: [],
  orgUsers: [],
  loading: false,
  fetchingAllUsers: false,
  error: null
}
